import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo
} from "react";
import {
  StyleSheet,
  View,
  TextInput,
  Text,
  ActivityIndicator,
  ScrollView,
  Picker,
  Image,
  TouchableOpacity,
  Pressable,
  Modal as RNModal
} from "react-native";
import firebase from "../firebase/firebaseConfig";
import ScreenSize from "../components/ScreenSize";
import LocationPicker from "../components/LocationPicker";
import { AntDesign, MaterialCommunityIcons } from "@expo/vector-icons";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import CityDropDown from "./CityDropDown";
import MapPreview from "../components/MapPreview";
import { FormControl, Input, Modal, Button, Switch } from "native-base";
import ChildImage from "./ChildImage";
import openNotificationWithIcon from '../components/openNotificationWithIcon'
import * as Animatable from 'react-native-animatable'
import HorizontalScrollView from "../components/HorizontalScrollView";

import { Image as antdImage, Popconfirm } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';

import { Carousel, Spin, Button as antdButton, Upload, Form, Modal as antdModal, message } from 'antd';
import { UploadOutlined } from "@ant-design/icons";

// import "./FileUpload.css";
import "./DisplayImages.css";
import { CSS } from '@dnd-kit/utilities';


import { MuuriComponent, AutoScroller, useData } from "muuri-react";
/* Utils & components */
import {
  Demo, Header, Select,
  // Input,
  Switch as dragSwitch, CardContent
} from "./components";

import { Items } from '../components/database/Database'

// import { UploadOutlined } from '@ant-design/icons';
// import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
// import {
//   arrayMove,
//   SortableContext,
//   useSortable,
//   verticalListSortingStrategy,
// } from '@dnd-kit/sortable';
// import { CSS } from '@dnd-kit/utilities';


export default function AddScreen({ navigation, route }) {
  const styles = useStyles();
  const screenSize = ScreenSize();
  const [modalVisible, setModalVisible] = useState(false)
  const [user, setUser] = useState(null);
  const [ShortDescription, setShortDescription] = useState("");
  const [LongDescription, setLongDescription] = useState("");
  const [Price, setPrice] = useState("");
  const [picker, setPicker] = useState("gamingLaptop");
  const [conditionPicker, setconditionPicker] = useState("new");
  const [phoneNumber, setphoneNumber] = useState(null);
  const [contactMethods, setContactMethods] = useState({ phoneNumber: true, chat: true })
  const [image, setImage] = useState([])

  const [Location, setLocation] = useState(null);
  const [address, setaddress] = useState(null);
  const [showLocationPicker, setShowLocationPicker] = useState(true)
  const [showCityDropDown, setShowCityDropDown] = useState(true)
  const [googleMapsCoordinates, setGoogleMapsCoordinates] = useState({})

  const [loadingOnlineData, setLoadingOnlineData] = useState(false)
  const [loadingLocalImages, setloadingLocalImages] = useState(false)
  const [loadingAddingData, setloadingAddingData] = useState(false)

  const [fileList, setFileList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [thumbUrl, setThumbUrl] = useState([])


  // const [coverImage, setcoverImage] = useState([])
  // use for loop when adding blob to firestore to add images one after another so that the index is the same

  // const DraggableUploadListItem = ({ originNode, file }) => {
  //   const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
  //     id: file.uid,
  //   });
  //   const style = {
  //     transform: CSS.Translate.toString(transform),
  //     transition,
  //     cursor: 'move',
  //   };
  //   return (
  //     <div
  //       ref={setNodeRef}
  //       style={style}
  //       // prevent preview event when drag end
  //       className={isDragging ? 'is-dragging' : ''}
  //       {...attributes}
  //       {...listeners}
  //     >
  //       {/* hide error tooltip when dragging */}
  //       {file.status === 'error' && isDragging ? originNode.props.children : originNode}
  //     </div>
  //   );
  // };

  const ref = useRef(null);
  const userDocRef = firebase
    .firestore()
    .collection("Users")
    .doc(firebase.auth().currentUser?.uid)
    .collection("Ads")
    .doc();

  useEffect(() => {
    setLoadingOnlineData(true)
    onAuthStateChanged(getAuth(), (data) => {
      if (data) {
        firebase
          .firestore()
          .collection("Users")
          .doc(firebase.auth().currentUser?.uid)
          .get()
          .then((snapshot) => {
            setUser(snapshot.data());
            setContactMethods(snapshot.data().contactMethods)
            setphoneNumber(snapshot.data().phoneNumber)
            if (snapshot.data().lastAdLocation?.coordinates != undefined) {
              setaddress(snapshot.data().lastAdLocation?.address)
              setLocation(snapshot.data().lastAdLocation?.coordinates)
              setShowCityDropDown(false)
              setShowLocationPicker(false)
            }
          });
        setLoadingOnlineData(false)

      } else {
        setUser(null);
        setLoadingOnlineData(false)

      }
    });
  }, [firebase.auth().currentUser?.uid]);


  function addToFirebase() {
    if (loadingLocalImages || loadingOnlineData || loadingAddingData) {
      openNotificationWithIcon('error', 'Please wait.', 'Loading data')
    } else if (image.length == 0) {
      openNotificationWithIcon('error', 'No Image', "You can't Publish your ad without an image")
    } else if (!address) {
      openNotificationWithIcon('error', "Location can't be empty", "Choosing a location is a must")
    } else if (ShortDescription.length < 4) {
      openNotificationWithIcon('error', "Title can't be empty", "You have to add title to your ad")
    } else if (contactMethods === undefined) {
      setContactMethods({ phoneNumber: true, chat: true })
      setModalVisible(true)
    } else {
      uploadToStorage(image)
    }
  }

  const uploadToStorage = async (images, coverUri) => {
    setloadingAddingData(true)

    const random = [];
    for (let item of images) {
      const response = await fetch(item.uri);
      const blob = await response.blob();
      // const childPath = `Ads/${firebase.auth().currentUser?.uid}/${userDocRef.id}/${image.indexOf(item)}`;
      const childPath = `Ads/${firebase.auth().currentUser?.uid}/${userDocRef.id}/${Math.random().toString(36)}`;
      const ref = firebase.storage().ref().child(childPath);
      // console.log(blob);
      await ref
        .put(blob)
        .then(function () {
          return ref.getDownloadURL();
        })
        .then(function (url) {
          delete item.uri
          random.push({ ...item, downloadURL: url, fileSize: blob.size });
        });
    }



    await userDocRef
      .set({
        ShortDescription: ShortDescription,
        LongDescription: LongDescription,
        Price: Price,
        Location: Location,
        Address: address,
        isAvailable: true,
        condition: conditionPicker,
        productImageList: random,
        creation: firebase.firestore.FieldValue.serverTimestamp(),
        ProductType: picker,
        adOwner: {
          uid: user.uid,
          displayName: user.displayName,
          photoURL: user.photoURL,
          phoneNumber: phoneNumber ? phoneNumber : null
        },
        id: userDocRef.id,
      })
      .then(() => {
        return firebase
          .firestore()
          .collection("Ads")
          .doc(userDocRef.id)
          .set({
            ShortDescription: ShortDescription,
            LongDescription: LongDescription,
            Price: Price,
            Location: Location,
            Address: address,
            isAvailable: true,
            condition: conditionPicker,
            productImageList: random,
            creation: firebase.firestore.FieldValue.serverTimestamp(),
            ProductType: picker,
            adOwner: {
              uid: user.uid,
              displayName: user.displayName,
              photoURL: user.photoURL,
              phoneNumber: phoneNumber ? phoneNumber : null
            },
            id: userDocRef.id,
          });
      })
      .then(() => {
        if (phoneNumber) {
          return firebase.firestore().collection('Users').doc(user.uid).update({ lastAdLocation: { address: address, coordinates: Location }, contactMethods: contactMethods, phoneNumber: phoneNumber })
        } else {
          return firebase.firestore().collection('Users').doc(user.uid).update({ lastAdLocation: { address: address, coordinates: Location }, contactMethods: contactMethods })
        }
      })
      .then(navigation.navigate("Home"))

    openNotificationWithIcon('success', 'Added Succsessfully', 'Your Ad Is Published')
    setloadingAddingData(false)
  };


  const pickOnMapHandler = () => {
    window.open(`https://maps.google.com/?ll=${googleMapsCoordinates.lat},${googleMapsCoordinates.lng}&z=13`)
  };

  // const pickOnMapHandler = () => {
  //   try {
  //     fetch(
  //       "https://maps.googleapis.com/maps/api/geocode/json?address=" +
  //       address +
  //       // product.Location.lat +
  //       // "," +
  //       // product.Location.lng +
  //       "&key=AIzaSyCEOCYVcrRh3fdxe199TzLIuRDE5lgBmuY"
  //     )
  //       // fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + location.coords.latitude + ',' + location.coords.longitude + '&key=AIzaSyCZ_l_v1gVoj9mHGOrL_ugNQLiCioZK4hs')
  //       .then((response) => response.json())
  //       .then((responseJson) => {

  //         // window.open(`https://maps.google.com/maps?q=LocationName@${product.Location.lat},${product.Location.lng}&iwloc=A`)
  //         // window.open(`https://www.google.com/maps/preview/@-15.623037,18.388672,8z`)
  //         // window.open(`https://www.google.com/maps/search/?api=1&center=${product.Location.lat},${product.Location.lng}&map_action=map`)
  //         // window.open(`https://maps.google.com/maps?f=q&amp;saddr=${product.Location.lat},${product.Location.lng}&amp;source=s_d&amp;hl=en&amp;z=15&amp;output=embed`)
  //         // window.open(`https://maps.google.com/?ll=${product.Location.lat},${product.Location.lng}`)
  //         // window.open("https://maps.google.com?q=" + product.Location.lat + "," + product.Location.lng) + 'api=1&map_action=map';
  //         // window.open(`https://maps.google.com/?ll=29.84215129999999,31.3333537`)
  //         window.open(`https://maps.google.com/?ll=${responseJson?.results[0]['geometry']['location'].lat},${responseJson?.results[0]['geometry']['location'].lng}&z=13`)

  //         // addressCoordinates = responseJson?.results[0]['geometry']['location']
  //         // console.log(responseJson?.results[0]['geometry']['location']);
  //       });

  //   } catch (err) {
  //     alert(
  //       // Alert.alert(
  //       "Could not fetch location!",
  //       "Please try again.",
  //       [{ text: "Okay" }]
  //     );
  //   }

  //   // window.open(`https://maps.google.com/?ll=${responseJson?.results[0]['geometry']['location'].lat},${responseJson?.results[0]['geometry']['location'].lng}&z=13`)
  //   // window.open("https://maps.google.com?q=" + Location.lat + "," + Location.lng);
  // };

  const locationPickedHandler = useCallback((props) => {
    setLocation(props);
    setShowCityDropDown(false)
    setShowLocationPicker(false)
    // console.log(location)
  }, []);

  const addressPickedHandler = useCallback((props) => {
    setaddress(props.results[props.results.length - 3].formatted_address);
    setGoogleMapsCoordinates(props.results[props.results.length - 3].geometry.location);
    setShowCityDropDown(false)
    setShowLocationPicker(false)
    // console.log(props.results[props.results.length - 3].geometry.location)
  }, []);

  const dropdownCoordinatesPickedHandler = useCallback((dropdownCoordinates) => {
    setLocation(dropdownCoordinates);
    setGoogleMapsCoordinates(dropdownCoordinates);
    setShowLocationPicker(false)
    setShowCityDropDown(true)
    // console.log(dropdownCoordinates)
  }, []);

  const dropdownAddressPickedHandler = useCallback((dropdownAddress) => {
    setaddress(dropdownAddress);
    setShowLocationPicker(false)
    setShowCityDropDown(true)
    // console.log(dropdownAddress)
  }, []);


  const imageCallback = (callback) => {
    if (callback != 'canceled') {
      setloadingLocalImages(false)
      setImage(callback)
    } else {
      setloadingLocalImages(false)
    }
  }


  const deleteItemById = id => {
    const filteredData = image.filter(item => item.uri !== id).map((image, index) => {
      return { ...image, orderNumber: index + 1 }
    })
    setImage(filteredData);
  }

  const changeImageOrder = item => {
    const arr = [...image];
    const fromIndex = arr.indexOf(item)
    const toIndex = 0;
    const element = arr.splice(fromIndex, 1)[0];
    arr.splice(toIndex, 0, element);
    ref.current?.goTo(0, false)
    setImage(arr)
  }

  // const AnimatedPressable = Animatable.createAnimatableComponent(Pressable)

  const slidesToShow = image?.length >= 3 ? 3 : image?.length

  const carouselWidth =
    // image?.length >= 3 ? screenSize.width / 6.1 :
    //   image?.length == 2 ? '80%' :
    //     image?.length == 1 ? '50%' :
    //       '100%'

    screenSize?.isWide ?
      image?.length >= 3 ? screenSize?.width / 6.1 :
        image?.length == 2 ? '80%' :
          image?.length == 1 ? '50%' :
            '100%' : '100%'






  const DraggableUploadListItem = ({ originNode, file }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: file.uid,
    });
    const style = {
      transform: CSS.Translate.toString(transform),
      transition,
      cursor: 'move',
    };
    return (
      <div
        ref={setNodeRef}
        style={style}
        // prevent preview event when drag end
        className={isDragging ? 'is-dragging' : ''}
        {...attributes}
        {...listeners}
      >
        {/* hide error tooltip when dragging */}
        {file.status === 'error' && isDragging ? originNode.props.children : originNode}
      </div>
    );
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setFileList((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };





  const tailLayout = {
    wrapperCol: {
      offset: 18,
      span: 12,
    },
  };

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    // setPreviewTitle(
    //   file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    // );
  };

  // const handleChange = (info) => {
  //       if (info.file.status === 'uploading') {
  //             setLoading(true);
  //             return;
  //       }
  //       if (info.file.status === 'done') {
  //             // Get this url from response in real world.
  //             getBase64(info.file.originFileObj, (url) => {
  //                   setLoading(false);
  //                   setImageUrl(url);
  //             });
  //       }
  // };

  const onRemove = async file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);

    setFileList(newFileList);
  };

  const handleChange = async ({ fileList, file }) => {
    const displayFileList = []
    const autoFileList = []

    autoFileList.push(fileList);

    setFileList(fileList.filter(file => file.status !== "error"));
    // //
    // setAutoFileList(fileList.filter(file => file.status !== "error"))
    //    
    for (let item of fileList) {
      const response = await getBase64(item.originFileObj);
      displayFileList.push({ url: response, uid: item.uid })
    }
    console.log('autoFileList : ', autoFileList);
    console.log('displayFileList : ', displayFileList);
    setThumbUrl(displayFileList)
    // handleFinishAuto(autoFileList)
    console.log('handleChange : ', fileList);
  }

  const beforeUpload = file => {
    if (!["image/jpeg", "image/png"].includes(file.type)) {
      message.error(`${file.name} is not a valid image type`, 2);
      return null;
    }
    return false;
  };


  const handleFinish = async values => {
    console.log('handleFinish : ', fileList);
    try {
      setSubmitting(true);
      await Promise.all(
        fileList.map(async file => {
          const fileName = `uploads/${Date.now()}-${file.name}`;
          const fileRef = firebase.storage().ref().child(fileName);
          try {
            const designFile = await fileRef.put(file.originFileObj);
            const downloadUrl = await designFile.ref.getDownloadURL();
            const item = {
              url: downloadUrl,
              path: fileName,
              uploadedAt: firebase.firestore.Timestamp.now(),
              orderNumber: fileList.indexOf(file) + 1
            };
            await firebase.firestore().collection("uploads").add(item);
          } catch (e) {
            console.log(e);
          }
        })
      );

      // setFileList([]);
      message.success(`Images added successfully.`, 2);
    } catch (err) {
      console.log(err);
      message.error(`Error adding images.`, 2);
    } finally {
      setSubmitting(false);
    }
  };


  const scrollElemRef = useRef();

  const children = useMemo(
    () =>
      Items.map(i => (
        <View style={{
          width: 135,
          margin: 10,
          zindex: 1
        }}>
          <Image style={{ height: 150, width: 150 }} source={i.productImage} />
        </View>
        // key = { i.id }
      ))
    // Pokedex.cards.map(pokeCard => (
    //       <PokeCard
    //             key={pokeCard.pokedexIndex}
    //             name={pokeCard.name}
    //             types={pokeCard.types}
    //             number={pokeCard.number}
    //             pokedexIndex={pokeCard.pokedexIndex}
    //       />
    // ))
    ,
    []
  );

  if (user === null) {
    return (
      <Text>
        You Have To Sign In First
      </Text>
    )
  }
  if (user != null) {
    return (
      <View style={{
        paddingHorizontal: screenSize.isWide ? screenSize.width / 4 : 0,
        flex: 1,
        // backgroundColor: 'red',
        // overflow: 'hidden',
        // height: '100%'
      }}>
        {/* {contextHolder} */}

        {loadingAddingData ?
          <RNModal transparent>
            <View
              style={{
                flex: 1,
                opacity: 0.8,
                backgroundColor: '#000',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ActivityIndicator size={'large'} />
              <Text style={{
                color: '#fff',
                fontSize: 16,
                fontWeight: '600',
                paddingTop: 10
              }}>Uploading</Text>
            </View>
          </RNModal>

          //  <Spin
          //   style={{
          //     flex: 1,
          //     position: 'absolute',
          //     left: 0,
          //     right: 0,
          //     top: 0,
          //     bottom: 0,
          //     alignItems: 'center',
          //     justifyContent: 'center',
          //     // width: '100%',
          //     // height: '100%'
          //   }}
          //   size="large"
          //   spinning={loadingAddingData}
          //   fullscreen={true} /> 

          : null}


        {/* <Button onPress={() => setloadingAddingData(true)}>
          open spin
        </Button> */}


        <ScrollView style={{
          // overflowX: screenSize.isWide ? 'visible' : 'hidden',
          // overflowY: screenSize.isWide ? 'visible' : 'visible',
          // flex: 1
          // paddingHorizontal: "4%",

        }} showsVerticalScrollIndicator={false}>

          {loadingOnlineData ? (
            <View>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Loading Online Data...
              </Text>
              <ActivityIndicator size={"large"} />
            </View>
          ) : null}

          {loadingLocalImages ?
            <View>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Loading Local Images...
              </Text>
              <ActivityIndicator size={"large"} />
            </View>
            : null}

          {image.length == 0 ?
            // <div className="mediaFormContainer">
            //   <Form onFinish={handleFinish}>
            //     <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
            //       <SortableContext items={fileList.map((i) => i.uid)} strategy={verticalListSortingStrategy}>
            //         <div className="uploadContainer">
            //           <Upload.Dragger
            //             showUploadList={false}
            //             listType='picture-card'
            //             fileList={fileList}
            //             onPreview={handlePreview}
            //             onChange={handleChange}
            //             onRemove={onRemove}
            //             multiple={true}
            //             accept="image/*"
            //             maxCount={10}
            //             beforeUpload={() => beforeUpload()}
            //             // itemRender={(originNode, file) => (
            //             //   <Demo>
            //             //     <dragSwitch ref={scrollElemRef}>
            //             //       <MuuriComponent
            //             //         // sort={sort.value}
            //             //         // sortOptions={sort.options}
            //             //         // filter={filterFunction}
            //             //         dragEnabled
            //             //         layoutDuration={300}
            //             //         layoutEasing={"ease-out"}
            //             //         dragAutoScroll={{
            //             //           sortDuringScroll: false,
            //             //           targets: [
            //             //             {
            //             //               element: scrollElemRef,
            //             //               axis: AutoScroller.AXIS_Y
            //             //             }
            //             //           ]
            //             //         }}
            //             //       >

            //             //       </MuuriComponent>
            //             //     </dragSwitch>
            //             //   </Demo>
            //             // )}

            //             itemRender={(originNode, file) => (
            //               <DraggableUploadListItem originNode={originNode} file={file} />
            //             )}
            //           // onDrop={e=>{console.log('Dropped files', e.dataTransfer.files);}}
            //           >
            //             <Text style={{ color: '#7b7b7b', fontSize: 16, fontWeight: '900', letterSpacing: 2, }}>
            //               Select Images
            //             </Text>

            //             <div className="uploadIcon">
            //               <UploadOutlined />
            //             </div>

            //             <View style={{}}>
            //               <Text style={{ color: '#A3A3A3', lineHeight: 14 }}>
            //                 {`Drag and drop here - OR - Click`}
            //               </Text>
            //             </View>
            //           </Upload.Dragger>
            //         </div>

            //       </SortableContext>
            //     </DndContext>

            //     {fileList.length !== 0 && <Form.Item {...tailLayout}>
            //       <antdButton disabled={submitting} shape="round" htmlType="submit">
            //         {submitting ? "Uploading" : "Upload"}
            //       </antdButton>
            //     </Form.Item>}
            //   </Form>

            //   <antdModal
            //     visible={previewVisible}
            //     footer={null}
            //     onCancel={handleCancel}
            //   >
            //     <img style={{ width: "100%" }} src={previewImage} />
            //   </antdModal>

            //   <div className="image-display-container">
            //     <div className="header">Uploaded Images</div>
            //     <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
            //       <Masonry gutter="10px">
            //         {thumbUrl.map((image, index) => (
            //           <div className="masonry-item" key={image.uid}>
            //             <Image style={{ height: 135, width: 135 }} source={image.url} key={image.uid} />
            //             <Popconfirm
            //               placement="topRight"
            //               title="Are you sure you want to delete this image?"
            //               onConfirm={() => handleImageDelete(image)}
            //               okText="Yes"
            //               cancelText="No"
            //             >
            //               <Button
            //                 title="Delete"
            //                 size="small"
            //                 className="delete-btn"
            //                 style={{
            //                   background: "white",
            //                   color: "#f33d3d",
            //                   border: "none",
            //                 }}
            //               >
            //                 <DeleteOutlined className="table-button-icon" size={30} />
            //               </Button>
            //             </Popconfirm>
            //           </div>
            //         ))}
            //       </Masonry>
            //     </ResponsiveMasonry>
            //   </div>
            // </div>

            //////////////////////////////////////  OLD CAMERA PIC TO CHOOSE IMGS //////////////////////////////////////////////////
            <View style={{
              justifyContent: 'center', alignItems: 'center', marginTop: 8,
              marginBottom: -15
              // backgroundColor: 'blue'
            }}>
              <TouchableOpacity
                style={{
                  justifyContent: 'center', alignSelf: 'center', alignItems: 'center',
                  // backgroundColor: 'red'
                  // backgroundColor: '#eaeaea',
                  // borderRadius: 50,
                  // height: 90, width: 90,
                }}
                onPress={() => {
                  setloadingLocalImages(true)
                  ChildImage(imageCallback)
                }}>
                {/* <MaterialCommunityIcons name="camera-plus-outline" size={70} color="black" /> */}
                <Image style={{
                  height: screenSize.ratio(8.5), width: screenSize.ratio(8.5),
                  // borderRadius: 50
                }} source={require('../assets/uploadImage2.png')} />
              </TouchableOpacity>
              {/* <Text style={{ fontSize: 14, fontWeight: '800', marginTop: 4 }}>Add Images To Your Product</Text> */}
            </View>
            : null}

          {/* ////////////////////////////////////////////////////// CAROUSEL ///////////////////////////////////////////////////////////////////////////// */}
          {/* <HorizontalScrollView
            data={image}
            // text='All'
            productsLength={image.length}
            onPressAll={(item, index) => { changeImageOrder(item, index) }}
          // navigation={props.navigation}
          >
          </HorizontalScrollView> */}

          {image?.length != 0 ?
            <View
              style={{
                width: '100%',
                height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                alignSelf: screenSize.isWide ? 'center' : undefined,
                justifyContent: screenSize.isWide ? 'center' : undefined,
              }}>

              <Carousel
                infinite={false}
                ref={ref}
                slidesToShow={screenSize.isWide ? slidesToShow : 1}
                style={{
                  height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                  alignSelf: 'center',
                  // marginRight: 10
                }}
                arrows={true}
                dots={true}
                waitForAnimate={true}
                draggable={true}
                swipeToSlide
              // slidesPerRow={4}
              >

                {image?.map((item, index) => {
                  return (
                    <View
                      key={index}
                      style={{
                      }}
                    >
                      <View>
                        <TouchableOpacity
                          onPress={() => { changeImageOrder(item, index) }}
                          style={{
                            // height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                            // width: carouselWidth,
                            // alignSelf: 'center',

                            // width: screenSize?.isWide ?
                            //   image?.length >= 3 ? screenSize?.width / 6.1 :
                            //     image?.length == 2 ? '80%' :
                            //       image?.length == 1 ? '50%' :
                            //         '100%' : '100%',
                            // width: screenSize.isWide ? carouselWidth : '100%',
                          }}>

                          <Image
                            source={item.downloadURL ? item.downloadURL : item.uri}
                            style={{
                              borderRadius: 3,
                              alignSelf: 'center',
                              alignItems: "center",
                              justifyContent: "center",
                              borderBottomRightRadius: 20,
                              borderBottomLeftRadius: 20,
                              height: screenSize?.isWide ? screenSize?.height / 3 : screenSize?.height / 2.1,
                              // width: screenSize.isWide ? carouselWidth : '100%',
                              width: screenSize?.isWide ?
                                image?.length >= 3 ? screenSize?.width / 6.3 :
                                  image?.length == 2 ? '80%' :
                                    image?.length == 1 ? '50%' :
                                      '100%' : '100%',

                              resizeMode: 'cover',
                              aspectRatio: 1,
                              marginHorizontal: 10,
                              borderRightWidth: 2,
                              borderLeftWidth: 2,
                              borderBottomWidth: 4,
                              borderColor: '#c9c9c9',
                              // width: screenSize.isWide ? screenSize.height / 3 : '100%',
                              // width: screenSize.isWide ? screenSize.ratio(6) : '100%',
                              // width: screenSize.isWide ? (screenSize.width / 3.5) / slidesToShow : '100%',
                              // height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                              // width: screenSize.isWide ? screenSize.width / 6.1 : '100%',
                              // backgroundColor: 'blue'

                              // borderRadius: 3,
                              // alignSelf: 'center',
                              // alignItems: "center",
                              // justifyContent: "center",
                              // borderBottomRightRadius: 20,
                              // borderBottomLeftRadius: 20,
                              // height: '100%',
                              // width: '100%',
                              // aspectRatio: 1,
                              // resizeMode: 'cover',
                            }} />

                          {index === 0 ?
                            <View
                              style={{
                                position: 'absolute',
                                width: screenSize.isWide ? screenSize.ratio(20) : screenSize.ratio(10),
                                // width: '30%',
                                height: '22%',
                                backgroundColor: 'green',
                                top: 3,
                                left: 3,
                                borderTopLeftRadius: 17,
                                borderBottomRightRadius: 17,
                                opacity: 0.7,
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}>
                              <Text
                                style={{
                                  fontSize: screenSize.isWide ? screenSize.ratio(70) : screenSize.ratio(35),
                                  // fontSize: '150%',
                                  color: '#fff',
                                  fontWeight: '900',
                                  opacity: 0.8
                                }}>
                                Cover
                              </Text>
                            </View>
                            : null}


                          <TouchableOpacity
                            style={{
                              position: 'absolute', top: 6,
                              right: 6,
                              opacity: 0.8
                            }}
                            onPress={() => { deleteItemById(item.uri) }}>
                            <MaterialCommunityIcons name="delete-circle-outline" size={24} color="red" />
                          </TouchableOpacity>
                        </TouchableOpacity>
                      </View>
                    </View>)
                })}
              </Carousel>

              {screenSize.isWide && image?.length > (screenSize.width / 2) / (screenSize.width / 6) ?
                // // {product.productImageList?.length != 0 && screenSize.isWide ?

                <>
                  <TouchableOpacity
                    style={{
                      position: 'absolute',
                      alignItems: "center",
                      alignSelf: 'flex-start',
                      top: screenSize.ratio(12),
                      // left: '100%',
                      // paddingRight: 600,
                      // marginRight: 30
                      left: -24
                    }}
                    onPress={() => { ref.current?.prev(); }}
                  // onPress={() => {
                  //   const eachItemOffset = scrollViewWidth / props.productsLength; // Divide by 10 because I have 10 <View> items
                  //   const _currentXOffset = currentXOffset - eachItemOffset;
                  //   ref.current.scrollTo({ x: _currentXOffset, y: 0, animated: true })
                  // }}
                  >

                    <AntDesign
                      name="leftcircle"
                      size={screenSize.ratio(30)}
                      color='#cacaca'
                    />
                  </TouchableOpacity>

                  <TouchableOpacity
                    style={{
                      position: 'absolute',
                      alignItems: "center",
                      alignSelf: 'flex-end',
                      top: screenSize.ratio(12),
                      right: -24
                      // left: '20%'
                    }}
                    onPress={() => { ref.current?.next(); }}
                  // onPress={() => {
                  //   const eachItemOffset = scrollViewWidth / props.productsLength; // Divide by 10 because I have 10 <View> items
                  //   const _currentXOffset = currentXOffset + eachItemOffset;
                  //   ref?.current?.scrollTo({ x: _currentXOffset, y: 0, animated: true })
                  // }}
                  >
                    <AntDesign
                      name="rightcircle"
                      size={screenSize.ratio(30)}
                      color='#cacaca'
                    />
                  </TouchableOpacity>
                </>

                // <View style={styles.ButtonBox}>
                //   <TouchableOpacity
                //     onPress={() => { ref.current?.prev(); }} >
                //     <AntDesign
                //       name="leftcircle"
                //       size={screenSize.ratio(30)}
                //       color='#9b9b9b'
                //     />
                //   </TouchableOpacity>

                //   <TouchableOpacity
                //     onPress={() => { ref.current?.next(); }}>
                //     <AntDesign
                //       name="rightcircle"
                //       size={screenSize.ratio(30)}
                //       color='#9b9b9b'
                //     />
                //   </TouchableOpacity>
                // </View>
                : null}
            </View>
            : null}
          {/* ////////////////////////////////////////////////////// CAROUSEL ///////////////////////////////////////////////////////////////////////////// */}


          <View style={{
            paddingHorizontal: 12,
            marginTop: 19,
          }}>
            <TextInput
              style={styles.textInput}
              maxLength={85}
              onChangeText={(ShortDescription) => setShortDescription(ShortDescription)}
              placeholder="Title"
            />
            <TextInput
              style={styles.textInput}
              onChangeText={(Price) => setPrice(Price)}
              placeholder="Price"
            />


            {/* ////////////////////////////////////////////////////// LOCATION ///////////////////////////////////////////////////////////////////////////// */}
            <View style={{ marginTop: 20, }}>
              {address ? <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#dadada",
                  borderRadius: 3,
                  marginBottom: 3,
                }}
              >
                <Text style={{ fontSize: 14, fontWeight: "600", textAlign: 'center', color: '#4f4f4f' }}>
                  {address}
                </Text>
              </View> : null}

              <View style={{
                flexDirection: 'row',
                justifyContent: showCityDropDown ? 'space-between' : 'space-around',
                alignItems: 'center',
                alignContent: 'center',
                alignSelf: 'center',
                width: '100%',
                // backgroundColor: 'green'
              }}>

                {Location ? (
                  <View style={{
                    borderRadius: 5,
                    overflow: 'hidden',
                    borderColor: "#ccc",
                    borderWidth: 1,
                    height: showCityDropDown ? '100%' : screenSize.ratio(10),
                    width: showCityDropDown ? '59.5%' : '100%',
                  }}>
                    <MapPreview
                      style={{ width: '100%', height: '100%' }}
                      location={Location}
                      onPress={pickOnMapHandler}
                    ></MapPreview>
                  </View>
                ) : null}

                {showLocationPicker || address == null ? (
                  // <View >
                  <View style={{ width: '40%', }}>
                    <LocationPicker
                      navigation={navigation}
                      onLocationPicked={locationPickedHandler}
                      onAddressPicked={addressPickedHandler}
                    // lastAdLocation={user?.lastAdLocation}
                    />
                  </View>
                ) : null}

                {showLocationPicker && showCityDropDown || address == null ? (
                  <View>
                    {/* <View style={{ width: '10%', alignSelf: 'center', alignItems: 'center', justifyContent: 'center' }}> */}
                    <Text style={{ color: '#133337', fontSize: 18, fontWeight: '700' }}>OR</Text>
                  </View>
                ) : null}

                {showCityDropDown || address == null ? (
                  // <View>
                  <View style={{ width: '40%' }}>
                    <CityDropDown
                      onLocationPicked={dropdownCoordinatesPickedHandler}
                      onAddressPicked={dropdownAddressPickedHandler}
                    // lastAdLocation={user?.lastAdLocation}
                    />
                  </View>
                ) : null}
              </View>


              {!showLocationPicker && !showCityDropDown && Location ? (
                // {!showLocationPicker && !showCityDropDown && Location ? (
                <TouchableOpacity
                  style={{ alignItems: "center", justifyContent: "center", position: 'absolute', bottom: -12, alignSelf: 'center' }}
                  onPress={() => {
                    setLocation(null);
                    setaddress(null);
                    setShowCityDropDown(true)
                    setShowLocationPicker(true)
                  }}
                >
                  <MaterialCommunityIcons
                    name="close-circle"
                    size={24}
                    color="#52575D"
                  />
                </TouchableOpacity>
              ) : null}

            </View>
            {/* ////////////////////////////////////////////////////// LOCATION ///////////////////////////////////////////////////////////////////////////// */}

            <TextInput
              multiline
              maxLength={2500}
              placeholder="Description"
              style={styles.textInputDescription}
              onChangeText={(LongDescription) =>
                setLongDescription(LongDescription)
              }
            />

            {/* /////////////////////////////////////////////  CPU ////////////////////////////////////////////////////////////////////////////// */}
            {/* <TextInput
              style={styles.textInput}
              onChangeText={(search) => fetchUsers(search)}
              placeholder='Cpu'
            />
            <FlatList
              numColumns={1}
              horizontal={false}
              data={cpu}
              renderItem={({ item }) => (
                <TouchableOpacity
                  // onPress={() => props.navigation.navigate('ProductInfo', {
                  //       // placeTitle: item.name,
                  //       data: item

                  // })}
                  onPress={() => setcpuFinal(item.model)
                    // props.navigation.navigate("KidsProfile", { uid: item.id })
                  }
                >
                  <Text style={styles.text}>{item.model}</Text>
                </TouchableOpacity>)} /> */}
            {/* ///////////////////////////////////////////////////////////   CPU  ///////////////////////////////////////////////////////////// */}

            <View style={{
              marginTop: 10,
              alignItems: 'center',
              justifyContent: 'center',
              paddingVertical: 6,
              width: '100%'
            }}>
              <Picker
                selectedValue={picker}
                onValueChange={(picker) => setPicker(picker)}
                style={{
                  width: '100%',
                  height: '100%',
                  fontSize: 16,
                  marginVertical: 4,
                  paddingVertical: 3,
                  flex: 1,
                  padding: 5,
                  borderRadius: 5
                }}
                mode="dropdown"
                itemStyle={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: 12,
                  // padding: 10,
                }}
              >
                {/* <Picker.Item label="اختار" value="اختار" /> */}
                <Picker.Item label="Gaming Laptop" value="gamingLaptop" />
                <Picker.Item label="Business Laptop" value="businessLaptop" />
                {/* <Picker.Item label="scarf" value="scarf" /> */}
                {/* <Picker.Item label="bonnet" value="bonnet" /> */}
                <Picker.Item label="Workstation" value="workstation" />
                <Picker.Item label="Other" value="other" />
                {/* <Picker.Item label="غير ذلك" value="غير ذلك" /> */}
              </Picker>

              <Picker
                selectedValue={conditionPicker}
                onValueChange={(conditionPicker) =>
                  setconditionPicker(conditionPicker)
                }
                style={{
                  width: '100%',
                  height: '100%',
                  fontSize: 16,
                  paddingVertical: 3,
                  marginVertical: 4,
                  flex: 1,
                  padding: 5,
                  borderRadius: 5,
                  // marginBottom: 2000
                }}
                mode="dropdown"
                itemStyle={{
                  color: "red",
                  fontWeight: "bold",
                  fontSize: 12,
                  // padding: 10,
                }}
              >
                <Picker.Item label="New" value="new" />
                <Picker.Item label="Used" value="used" />
              </Picker>
            </View>
          </View >




          <View
            style={{
              // position: 'absolute',
              alignSelf: 'center',
              width: screenSize.isWide ? '96.5%' : '95%',
              // width: screenSize.isWide ? screenSize.width / 2.06 : '95%',
              height: '6%',
              borderRadius: 5,
              bottom: 5,
              marginTop: 20
              // overflow: 'hidden',
            }}
          >

            <Button
              disabled={loadingAddingData || loadingLocalImages || loadingOnlineData}
              style={{ opacity: loadingAddingData || loadingLocalImages || loadingOnlineData ? 0.3 : 1 }}
              onPress={() => {
                addToFirebase()
                // setloadingAddingData(true)
                // setLoadingModalVisible(true)
              }} >
              Add
            </Button>
          </View>

        </ScrollView >




        <Modal
          // rounded='md'
          // safeAreaY={true}
          useRNModal
          isOpen={modalVisible}
          onClose={() => {
            // addToFirebase();
            setModalVisible(false)
          }}
          style={{
            flex: 1,
            position: 'absolute',
            // ...StyleSheet.absoluteFillObject,
            ...StyleSheet.absoluteFillObject,
            backgroundColor: "rgba(0, 0, 0, 0.3)",
            zIndex: 1,
            // height: '135%'
          }}
        >
          <Modal.Content
          // maxWidth="400px"
          >
            <Modal.CloseButton />
            <Modal.Header>Choose Contact Methods</Modal.Header>
            <Modal.Body>
              <FormControl>
                <FormControl.Label>We Recommend That You Add A Phone Number As It Increases The Chance To Be Contacted</FormControl.Label>

                {user.phoneNumber == undefined ?
                  <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 20 }}>
                    <Text style={{ fontSize: 15 }}>Add Your Phone Number</Text>
                    <Input onChangeText={(text) => { setphoneNumber(text) }} />
                  </View>
                  : null}

                <View style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 25,
                  marginBottom: 5
                }}>
                  <Text style={{ fontSize: 15 }}>Phone Number</Text>
                  <Switch
                    isDisabled={contactMethods?.chat == false}
                    onToggle={() => {
                      setContactMethods(prevState => ({ ...prevState, phoneNumber: !prevState.phoneNumber }));
                    }}
                    // name="Phone Number"
                    value={contactMethods?.phoneNumber}
                    offTrackColor="orange.100" onTrackColor="orange.200" onThumbColor="orange.500" offThumbColor="orange.50" />
                </View>

                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Text style={{ fontSize: 15 }}>Chat</Text>
                  <Switch
                    isDisabled={contactMethods?.phoneNumber == false || !phoneNumber || phoneNumber?.length < 11}
                    onToggle={() => {
                      setContactMethods(prevstate => ({ ...prevstate, chat: !prevstate.chat }))
                    }}
                    // name="Chat"
                    value={contactMethods?.chat}
                    offTrackColor="indigo.100" onTrackColor="indigo.200" onThumbColor="indigo.500" offThumbColor="indigo.50" />
                </View>

              </FormControl>
            </Modal.Body>
            <Modal.Footer>

              <Button.Group space={2}>
                <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                  setModalVisible(false);
                }}>
                  Cancel
                </Button>
                <Button onPress={() => {
                  setModalVisible(false);
                  addToFirebase();
                }}>
                  Save
                </Button>
              </Button.Group>

            </Modal.Footer>
          </Modal.Content>
        </Modal>

      </View >
    );
  }
}

function useStyles() {
  return StyleSheet.create({
    textInput: {
      borderBottomColor: "#ccc",
      borderBottomWidth: 1,
      paddingVertical: 4,
      paddingHorizontal: 2,
      marginBottom: 10,
    },
    textInputDescription: {
      borderColor: "#ccc",
      // marginBottom: 30,
      marginTop: 25,
      // marginTop: 27,
      // paddingVertical: 60,
      paddingHorizontal: 2,
      borderWidth: 1,
      height: 100,
      borderRadius: 5

      // alignItems: "flex-start",
      // justifyContent: 'space-around',
      // borderBottomColor: '#ccc',
      // borderBottomWidth: 1,
    },
    textInputDelete: {
      borderBottomColor: "#ccc",
      borderBottomWidth: 5,
      marginBottom: 30,
      paddingVertical: 20,
      paddingHorizontal: 2,
      borderTopColor: "#0000ff",
      // borderRadius:50,
      borderEndWidth: 20,
    },
    locationPicker: {
      marginBottom: 15,
    },
    mapPreview: {
      marginBottom: 10,
      width: "100%",
      height: 150,
      borderColor: "#ccc",
      borderWidth: 1,
      borderRadius: 10
    },
    actions: {
      flexDirection: "row",
      justifyContent: "space-around",
      width: "100%",
    },
    imagePicker: {
      alignItems: "center",
      marginBottom: 15,
    },
    imagePreview: {
      width: "100%",
      height: 200,
      marginBottom: 10,
      justifyContent: "center",
      alignItems: "center",
      borderColor: "#ccc",
      borderWidth: 1,
    },
    image: {
      width: "100%",
      height: "100%",
    },
    loadingIndicator: {
      zIndex: 5,
      width: "100%",
      height: "100%",
    },
    ButtonBox: {
      position: "absolute",
      flexDirection: "row",
      alignItems: "center",
      top: ScreenSize().ratio(12),
      justifyContent: "space-between",
      alignContent: "center",
      alignSelf: "center",
      width: "107.5%",
      // backgroundColor: 'red'
    },
    sheet: {
      // backgroundColor: "rgba(0, 0, 0, 0.3)",
      backgroundColor: "white",
      padding: 16,
      height: '30%',
      // height: ScreenSize().height / 1.5,
      width: "70%",
      position: "absolute",
      // bottom: -ScreenSize().height / 6,
      borderRadius: 20,
      zIndex: 1,
      alignSelf: 'center',
      bottom: ScreenSize().height * 0.35
      // marginHorizontal: 1000,
      // bottom: -20 * 7,
      // borderTopRightRadius: 20,
      // borderTopLeftRadius: 20,
    },
    backdrop: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 1,
    },
  })
}



