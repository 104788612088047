import { useCallback } from "react";

// Return the filter.
export function useFilter(name, type) {
    name = name.toLowerCase();
    type = type.toLowerCase();

    return useCallback(
        function (data) {
            var isSearchMatch = !name
                ? true
                : data.name.toLowerCase().indexOf(name) > -1;
            var isFilterMatch = type === "all" ? true : data.type.indexOf(type) > -1;
            return isSearchMatch && isFilterMatch;
        },
        [name, type]
    );
}

// The list of pokemons.
export const Pokedex = {
    cardInfo: ["Type", "Name", "Number"],
    types: [
        "All",
        "Bug",
        "Dragon",
        "Electric",
        "Fighting",
        "Fire",
        "Flying",
        "Ghost",
        "Grass",
        "Ground",
        "Ice",
        "Normal",
        "Poison",
        "Psychic",
        "Rock",
        "Water"
    ],
    cards: [
        {
            name: "Gastly",
            types: ["ghost", "poison"],
            number: "092",
            pokedexIndex: "108"
        },
        {
            name: "Haunter",
            types: ["ghost", "poison"],
            number: "093",
            pokedexIndex: "109"
        },
        {
            name: "Gengar",
            types: ["ghost", "poison"],
            number: "094",
            pokedexIndex: "110"
        },
        {
            name: "Mega Gengar",
            types: ["ghost", "poison"],
            number: "094",
            pokedexIndex: "111"
        },
        {
            name: "Onix",
            types: ["rock", "ground"],
            number: "095",
            pokedexIndex: "112"
        },
        {
            name: "Dragonite",
            types: ["dragon", "flying"],
            number: "149",
            pokedexIndex: "172"
        },
        {
            name: "Mewtwo",
            types: ["psychic"],
            number: "150",
            pokedexIndex: "173"
        },
        {
            name: "Mega Mewtwo Y",
            types: ["psychic"],
            number: "150",
            pokedexIndex: "175"
        },
        {
            name: "Mega Mewtwo X",
            types: ["psychic", "fighting"],
            number: "150",
            pokedexIndex: "174"
        },
        {
            name: "Mew",
            types: ["psychic"],
            number: "151",
            pokedexIndex: "176"
        }
    ]
};

// Return the name to find the pokemon image.
export function resolveSrcName(name) {
    if (name.indexOf("Mega") > -1) {
        name = name.split(" ").slice(1);
        name[0] = name[0] + "-Mega";
        return name.join("_");
    }

    if (name.indexOf(" male") > -1 || name.indexOf(" female") > -1) {
        return name.split(" ")[0];
    }

    if (name === "Farfetch'd") {
        return "Farfetch_27d";
    }

    if (name.indexOf(" ") > -1) {
        return name.replace(" ", "_");
    }

    return name;
}
