import { View, Text, TouchableOpacity, ScrollView, StyleSheet, Image } from 'react-native'
import React, { useState, useRef, useEffect } from 'react'
import { AntDesign } from '@expo/vector-icons'
import ScreenSize from './ScreenSize';
// import ProductCard from './ProductCard';

const HorizontalScrollView = (props) => {
    const styles = useStyles();
    const screenSize = ScreenSize();
    const [scrollViewWidth, setScrollViewWidth] = useState(0)
    const [currentXOffset, setCurrentXOffset] = useState(0)
    const ref = useRef()

    // useEffect(() => {
    //     console.log((screenSize.width - screenSize.width / 11) / (screenSize.width / 9))
    // }, [])

    return (
        <View style={{ marginTop: screenSize.ratio(40) }}>

            {props.text ?
                <View
                    style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: 'center',
                    }}
                >
                    <View
                        style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginLeft: 6,
                        }}
                    >
                        <Text
                            style={{
                                fontSize: 25,
                                color: '#004c54',
                                // color: '#008080',
                                fontWeight: "800",
                                letterSpacing: 1,
                            }}
                        >
                            {props.text}
                        </Text>
                        <Text
                            style={{
                                fontSize: 14,
                                color: '#000',
                                fontWeight: "400",
                                opacity: 0.9,
                                marginLeft: 10,
                            }}
                        >
                            {props.productsLength}
                        </Text>
                    </View>

                    <TouchableOpacity onPress={props.onPressAll}>
                        <Text
                            style={{
                                fontSize: 20,
                                color: 'blue',
                                fontWeight: "600",
                                marginRight: 3,
                                borderBottomWidth: screenSize.isWide ? 1 : 0
                            }}>
                            See All
                        </Text>
                    </TouchableOpacity>
                </View>
                : null}


            <ScrollView
                // persistentScrollbar={false}
                // centerContent={true}
                // contentContainerStyle={{ flexGrow: 1 }}
                pagingEnabled={true}
                scrollEventThrottle={16}
                style={{
                    width: '100%',
                    marginTop: props.text ? 7 : 0,
                    // backgroundColor: 'red',
                    // height: screenSize.height / 2.8,
                    // gab: 700
                    // flexWrap: 'wrap',
                    // display: 'flex',
                    // flexDirection: 'row',
                }}
                horizontal={true}
                showsHorizontalScrollIndicator={false}
                ref={ref}
                onContentSizeChange={(w, h) => { setScrollViewWidth(w) }}
                onScroll={({ nativeEvent }) => {
                    const newXOffset = nativeEvent.contentOffset.x
                    setCurrentXOffset(newXOffset)
                }}
            >
                {props.data?.map((item, index) => {
                    return (
                        // <View key={index} >
                        <TouchableOpacity
                            key={index}
                            style={{
                                width: screenSize.isWide
                                    ? screenSize.width / 8
                                    : screenSize.width / 2.4,
                                height: '100%',
                                marginHorizontal: 3,
                                marginTop: 3,
                                // backgroundColor: 'blue',


                                // marginRight: 5,
                                // overflow: 'visible'
                            }}
                            // style={{
                            //     width: screenSize.isWide
                            //         ? screenSize.width / 9
                            //         : screenSize.width / 2.2,
                            //     marginVertical: 5,
                            //     marginHorizontal: 3,
                            //     // marginRight: 5,
                            //     overflow: 'visible'
                            // }}
                            onPress={() => { props.navigation.navigate('ProductInfo', { productId: item.id }) }}
                        >
                            <Image
                                source={item.productImageList[0].downloadURL}
                                style={{
                                    width: undefined,
                                    height: undefined,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    borderRadius: 10,
                                    aspectRatio: 1,
                                    resizeMode: "cover",
                                    borderWidth: 2,
                                    borderColor: '#c9c9c9',
                                    // shadowOffset: 50,
                                    // shadowOpacity: 30,
                                    // elevation: 30,

                                    // elevation: 5,
                                    // zIndex: 1,
                                    // shadowColor: '#a9a9a9',
                                    // shadowOffset: { width: 2, height: 3 },
                                    // shadowOpacity: 1,
                                    // shadowRadius: 10,
                                }}
                            ></Image>

                            {item.isAvailable === false ?
                                <View
                                    style={{
                                        position: 'absolute',
                                        width: '36%',
                                        height: '22%',
                                        backgroundColor: 'red',
                                        top: -3,
                                        left: -3,
                                        borderTopLeftRadius: 17,
                                        borderBottomRightRadius: 17,
                                        opacity: 0.8,
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}>
                                    <Text
                                        style={{
                                            fontSize: screenSize.ratio(60),
                                            color: '#fff',
                                            fontWeight: '900',
                                            letterSpacing: 1,
                                        }}>
                                        Sold
                                    </Text>
                                </View> : null}

                            <View style={{ marginTop: 5 }}>
                                <View>
                                    <Text
                                        numberOfLines={3}
                                        style={{
                                            fontSize: screenSize.ratio(70),
                                            color: "#242424",
                                            fontWeight: "600",
                                        }}
                                    >
                                        {item.ShortDescription}
                                    </Text>
                                </View>

                                <View
                                    style={{
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: 'flex-start'
                                        // right: 4,
                                    }}
                                >
                                    <Text style={{
                                        fontSize: screenSize.ratio(55), fontWeight: "bold",
                                        color: '#004c54'
                                    }}>
                                        {/* {" "} */}
                                        EGP{" "}
                                    </Text>
                                    <Text style={{
                                        fontSize: screenSize.ratio(55), fontWeight: "bold",
                                        color: '#004c54'
                                    }}>
                                        {/* {" "} */}
                                        {" " + item.Price}
                                    </Text>
                                </View>
                            </View>
                        </TouchableOpacity>
                        // </View>
                        // <ProductCard
                        //     item={item}
                        //     key={index}
                        //     screenSize={screenSize}
                        //     onPress={onPressCard}
                        // />
                    )
                })}

            </ScrollView>

            {screenSize.isWide && props.data?.length > (screenSize.width - screenSize.width / 11) / (screenSize.width / 7) ?
                // <View >
                <>
                    <TouchableOpacity
                        style={{
                            position: 'absolute',
                            alignItems: "center",
                            alignSelf: 'flex-start',
                            bottom: '50%',
                            // left: '100%',
                            // paddingRight: 600,
                            // marginRight: 30
                            left: -20
                        }}
                        onPress={() => {
                            const eachItemOffset = scrollViewWidth / props.productsLength; // Divide by 10 because I have 10 <View> items
                            const _currentXOffset = currentXOffset - eachItemOffset;
                            ref.current.scrollTo({ x: _currentXOffset, y: 0, animated: true })
                        }} >

                        <AntDesign
                            name="leftcircle"
                            size={screenSize.ratio(30)}
                            color='#cacaca'
                        />
                    </TouchableOpacity>

                    <TouchableOpacity
                        style={{
                            position: 'absolute',
                            alignItems: "center",
                            alignSelf: 'flex-end',
                            bottom: '50%',
                            right: -22
                            // left: '20%'
                        }}
                        onPress={() => {
                            const eachItemOffset = scrollViewWidth / props.productsLength; // Divide by 10 because I have 10 <View> items
                            const _currentXOffset = currentXOffset + eachItemOffset;
                            ref?.current?.scrollTo({ x: _currentXOffset, y: 0, animated: true })
                        }}>
                        <AntDesign
                            name="rightcircle"
                            size={screenSize.ratio(30)}
                            color='#cacaca'
                        />
                    </TouchableOpacity>
                </>
                : null}
            {/* {screenSize.isWide && props.data?.length > (screenSize.width - screenSize.width / 11) / (screenSize.width / 7) ?
                <View style={styles.ButtonBox}>
                    <TouchableOpacity
                        onPress={() => {
                            const eachItemOffset = scrollViewWidth / props.productsLength; // Divide by 10 because I have 10 <View> items
                            const _currentXOffset = currentXOffset - eachItemOffset;
                            ref.current.scrollTo({ x: _currentXOffset, y: 0, animated: true })
                        }} >

                        <AntDesign
                            name="leftcircle"
                            size={screenSize.ratio(30)}
                            color='#cacaca'
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        onPress={() => {
                            const eachItemOffset = scrollViewWidth / props.productsLength; // Divide by 10 because I have 10 <View> items
                            const _currentXOffset = currentXOffset + eachItemOffset;
                            ref?.current?.scrollTo({ x: _currentXOffset, y: 0, animated: true })
                        }}>
                        <AntDesign
                            name="rightcircle"
                            size={screenSize.ratio(30)}
                            color='#cacaca'
                        />
                    </TouchableOpacity>
                </View>
                : null} */}
        </View>
    )
}

export default HorizontalScrollView


function useStyles() {
    return StyleSheet.create({
        textInput: {
            fontSize: ScreenSize().isWide
                ? ScreenSize().width / 50
                : ScreenSize().width / 19,
            paddingLeft: 10,
            borderWidth: 2,
            borderRadius: 8,
            borderColor: "#ccc",
            paddingVertical: 10,
            marginTop: 6,
            paddingHorizontal: 20,
            shadowOffset: 50,
            shadowOpacity: 30,
            elevation: 30,
        },
        textInputModalSearch: {
            fontSize: ScreenSize().isWide
                ? ScreenSize().width / 50
                : ScreenSize().width / 19,
            paddingLeft: 10,
            borderWidth: 2,
            borderRadius: 8,
            borderColor: "#ccc",
            paddingVertical: 10,
            marginTop: 6,
            paddingHorizontal: 20,
            shadowOffset: 50,
            shadowOpacity: 30,
            elevation: 30,
            width: '93%'
        },
        text: {
            fontSize: 24,
            fontStyle: "italic",
            paddingLeft: 13,
        },
        textShortDes: {
            fontSize: 12,
            fontWeight: "600",
            // backgroundColor: '#444'
            // height: '30%',
            // width: 100,
            // marginLeft: 7,
            // top: 2
        },
        textLongDes: {
            fontSize: 10,
            fontWeight: "400",
            color: "#000",
            opacity: 0.9,
            width: 120,
            // jus: 'center'
            // maxHeight: '30%',
            // height: '100%',
            // backgroundColor: 'grey',
            // bottom: 2,
            // marginLeft: 7,
            // marginTop: 1,
        },
        image: {
            height: 70,
            width: 70,
            borderRadius: 10,
        },
        itemContainer: {
            flexDirection: "row",
            alignItems: "center",
            marginLeft: 10,
            marginTop: 10,
            // top: 0
        },
        AdsWraper: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            // alignItems: 'center',
            justifyContent: 'center'
        },
        centeredView: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 22,
        },
        modalView: {
            margin: 20,
            backgroundColor: 'white',
            borderRadius: 20,
            padding: 35,
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: {
                width: 0,
                height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5,
        },
        button: {
            borderRadius: 20,
            padding: 10,
            elevation: 2,
        },
        buttonOpen: {
            backgroundColor: '#F194FF',
        },
        buttonClose: {
            backgroundColor: '#2196F3',
        },
        textStyle: {
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
        },
        modalText: {
            marginBottom: 15,
            textAlign: 'center',
        },
        ButtonBox: {
            // position: 'absolute',
            flexDirection: "row",
            alignItems: "center",
            // top: ScreenSize().ratio(13),
            // top: props.text ? '35%' : '20%',
            bottom: '50%',
            justifyContent: "space-between",
            alignContent: "center",
            // width: "104.9%",
            alignSelf: "center",
            backgroundColor: 'green'


            // position: 'absolute',
            // flexDirection: "row",
            // alignItems: "center",
            // // top: ScreenSize().ratio(13),
            // // top: props.text ? '35%' : '20%',
            // bottom: '50%',
            // justifyContent: "space-between",
            // alignContent: "center",
            // width: "104.9%",
            // alignSelf: "center",
            // backgroundColor: 'green'
            // // paddingHorizontal: -70,
            // // paddingHorizontal: 3,
            // // flex: ScreenSize().isWide ? 1 : 0,
            // // display: ScreenSize().isWide ? 'flex' : 'none'
            // // padding: 2,
            // // justifyContent: 'center',
            // // right: 0,
        },
    });
}