import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import Drawer from './Drawer'
// import MyAccount from './Screens/MyAccount'
import { useFonts } from 'expo-font';
import { NativeBaseProvider } from 'native-base';
import { useEffect } from 'react';
import { LogBox } from 'react-native';

// import {
// LogBox
// , YellowBox 
// } from 'react-native';
// import { LogBox, YellowBox } from 'react-native-web';
// import * as SplashScreen from 'expo-splash-screen';
// import { Text } from 'react-native';
// import { navigationRef, isReadyRef } from './navigationRef';

// SplashScreen.preventAutoHideAsync();



// import * as React from 'react';

// export const navigationRef = React.createRef();
// export const isReadyRef = React.createRef()

// export function navigate(name, params) {
//   navigationRef.current?.navigate(name, params);
// }

export default function App() {
  LogBox.ignoreLogs(['Warning: ...'])
  LogBox.ignoreLogs(['Attempted import error:'])
  LogBox.ignoreAllLogs()
  console.disableYellowBox = true

  useEffect(() => {
    LogBox.ignoreLogs(['In React 18, SSRProvider is not necessary and is a noop. You can remove it from your app.']);
  }, []);
  // YellowBox.ignoreWarnings('Attempted import error:')
  // YellowBox.ignoreWarnings(['Attempted import error:'])
  // LogBox.ignoreLogs(['Attempted import error:'])
  // LogBox.ignoreLogs(['setNativeProps is deprecated.'])
  // LogBox.ignoreAllLogs()
  // LogBox .ignoreAllLogs(true)
  // React.useEffect(() => {
  //   return () => {
  //     isReadyRef.current = false
  //   };
  // }, []);

  const [fontsLoaded] = useFonts({
    'BabyAletha': require('./assets/BabyAletha.otf'),
    'AlphaDanthe': require('./assets/AlphaDanthe.otf'),
    'Avigea': require('./assets/Avigea.otf'),
    'BelintaCinta': require('./assets/BelintaCinta.otf'),
    'Santana': require('./assets/Santana.otf'),
  });

  // const onLayoutRootView = useCallback(async () => {
  //   if (fontsLoaded) {
  //     await SplashScreen.hideAsync();
  //   }
  // }, [fontsLoaded]);

  if (!fontsLoaded) {
    return null;
  }

  // React.useEffect(() => {
  //   Promise.race([
  //     getInitialState(),
  //     new Promise((resolve) => setTimeout(resolve, 150)),
  //   ])
  //     .catch((e) => {
  //       console.error(e);
  //     })
  //     .then((state) => {
  //       if (state !== undefined) {
  //         setInitialState(state);
  //       }
  //       setIsReady(true);
  //     });
  // }, [getInitialState]);

  // const [isReady, setIsReady] = React.useState(false);
  // const [initialState, setInitialState] = React.useState();
  // const ref = React.useRef();
  // const { getInitialState } = useLinking(ref, {
  //   prefixes: ['deeplink://'],
  //   config: {
  //     initialRouteName: 'HomeScreen',
  //     Home: 'HomeScreen',
  //     // Details: {
  //     //   path: 'Details/:itemId',
  //     //   parse: {
  //     //     itemId: null,
  //     //   },
  //     // },
  //   },
  //   getPathFromState(state, config) {
  //     console.log(state);
  //   },
  // });

  // if (!isReady) {
  //   return null;
  // }
  return (
    <NativeBaseProvider>

      <NavigationContainer
        // fallback={<Text>Loading...</Text>}
        linking={true}
      // initialState={initialState}
      // ref={navigationRef}
      // onReady={() => {
      //   isReadyRef.current = true;
      // }}
      >

        <Drawer />
        {/* <MyAccount /> */}
      </NavigationContainer>
    </NativeBaseProvider>
  );
}
