import React from "react";
import { View, Image, StyleSheet, TouchableOpacity } from "react-native";

// import ENV from '../env';

const MapPreview = (props) => {
  let imagePreviewUrl;

  if (props.location) {
    // imagePreviewUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${props.location.lat},${props.location.lng}&zoom=14&size=400x200&maptype=roadmap&markers=color:red%7Clabel:A%7C${props.location.lat},${props.location.lng}&key=AIzaSyDis-2EGL3Ij51MXxLm1ULiH4jUaDChCRc`;
    imagePreviewUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${props.location.lat
      },${props.location.lng}&zoom=${10
      // props.zoom ? props.zoom : 11
      }&size=640x640&scale=2&maptype=roadmap&markers=color:red%7Clabel:A%7C${props.location.lat
      },${props.location.lng}&key=AIzaSyACAYvtnILPNKZ3nF9nQlr-KST_Ed3RffA`;
  }
  // AIzaSyCWjtq1LG017Ey7rj5DnK25JRixmGD0_W8

  // console.log("https://maps.googleapis.com/maps/api/staticmap?center=${props.location.lat},${props.location.lng}&zoom=14&size=400x200&maptype=roadmap&markers=color:red%7Clabel:A%7C${props.location.lat},${props.location.lng}&key=AIzaSyCWjtq1LG017Ey7rj5DnK25JRixmGD0_W8")

  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={{ ...styles.mapPreview, ...props.style }}
    >
      {props.location ? (
        <Image style={styles.mapImage} source={{ uri: imagePreviewUrl }} />
      ) : (
        props.children
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  mapPreview: {
    justifyContent: "center",
    alignItems: "center",
  },
  mapImage: {
    width: "100%",
    height: "100%",
    // flex: 1
  },
});

export default MapPreview;
