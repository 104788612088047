import React, { useState, useMemo, useRef } from "react";
import ReactDom from "react-dom";
/* Muuri-react */
import { MuuriComponent, AutoScroller, useData } from "muuri-react";
/* Utils & components */
import { Demo, Header, Select, Input, Switch, CardContent } from "./components";
import { Pokedex, useFilter } from "./utils";
/* Style */
import "./style.css";
import { Items } from '../components/database/Database'
import { View, Image } from 'react-native'
export default function MessagesScreen() {
      // // Sort state.
      // const [sort, setSort] = useState({
      //       value: "type",
      //       options: { descending: true }
      // });

      // // Filter state.
      // const [filter, setFilter] = useState({
      //       name: "",
      //       type: ""
      // });

      // // Filter method.
      // const filterFunction = useFilter(filter.name, filter.type);

      // Memoize the children to improve performance.
      const children = useMemo(
            () =>
                  Items.map(i => (
                        <View style={{
                              width: 135,
                              margin: 10,
                              zindex: 1
                        }}>
                              <Image style={{ height: 150, width: 150 }} source={i.productImage} />
                        </View>
                        // key = { i.id }
                  ))
            // Pokedex.cards.map(pokeCard => (
            //       <PokeCard
            //             key={pokeCard.pokedexIndex}
            //             name={pokeCard.name}
            //             types={pokeCard.types}
            //             number={pokeCard.number}
            //             pokedexIndex={pokeCard.pokedexIndex}
            //       />
            // ))
            ,
            []
      );

      // Scroll container ref.
      const scrollElemRef = useRef();

      // Render.
      return (
            <Demo>
                  <Switch ref={scrollElemRef}>
                        <MuuriComponent
                              // sort={sort.value}
                              // sortOptions={sort.options}
                              // filter={filterFunction}
                              dragEnabled
                              layoutDuration={300}
                              layoutEasing={"ease-out"}
                              dragAutoScroll={{
                                    sortDuringScroll: false,
                                    targets: [
                                          {
                                                element: scrollElemRef,
                                                axis: AutoScroller.AXIS_Y
                                          }
                                    ]
                              }}
                        >
                              {children}
                        </MuuriComponent>
                  </Switch>
            </Demo>
      );
};

const PokeCard = props => {
      const { types, number, name } = props;
      // e.g. Venosaurus -> Grass Poison.
      const type = `${types[0]} ${types[1] || ""}`;
      // These data will be used for sorting and filtering.
      useData({ name, type, number });
      // Return the content.
      return <CardContent {...props} />;
};

// ReactDom.render(<MessagesScreen />, document.getElementById("root"));
