import React, { useState, useEffect, useRef } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  StyleSheet,
  Modal,
  Pressable
} from "react-native";
import { COLOURS } from "../components/database/Database";
import ScreenSize from "../components/ScreenSize";
import {
  Carousel,
  //  Image as AntdImage
} from 'antd';
import firebase from "../firebase/firebaseConfig";
import { doc, updateDoc, serverTimestamp, getDoc, setDoc } from "firebase/firestore";
import { AntDesign, Feather, MaterialCommunityIcons, Ionicons, Entypo } from "@expo/vector-icons";
import * as Linking from "expo-linking";
import * as Sharing from "expo-sharing";
import MapPreview from "../components/MapPreview";
import ImageViewer from 'react-native-image-zoom-viewer';
import * as Animatable from 'react-native-animatable'
// import Alert from "react-native-awesome-alerts";

import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal as antdModal, Space } from 'antd';
import openNotificationWithIcon from "../components/openNotificationWithIcon";

import Animated, {
  useSharedValue,
  withSpring,
  useAnimatedStyle,
  Extrapolate,
  interpolate,
} from "react-native-reanimated";
// import ReactNativeZoomableView from '@dudigital/react-native-zoomable-view/src/ReactNativeZoomableView';
// import { ImageZoom } from '@likashefqet/react-native-image-zoom';
// import Carousel from 'react-native-snap-carousel';

const ProductInfo = (props) => {
  const screenSize = ScreenSize();
  const styles = useStyles();
  const [user, setUser] = useState([]);
  const [userB, setUserB] = useState(null);
  const [loading, setloading] = useState(false);
  const [product, setproduct] = useState([])
  const [dialog, setDialog] = useState(null);
  const [currentUserLike, setCurrentUserLike] = useState(false)
  const combinedId =
    user?.uid > product?.adOwner?.uid
      ? user?.uid + product?.adOwner?.uid
      : product?.adOwner?.uid + user?.uid;
  const db = firebase.firestore();

  const [pictures, setPictures] = useState(product)
  // const [pictures, setPictures] = useState(product?.length != 0 ? product : product)
  const ref = useRef(null);
  const rubberBandAnimeRef = useRef()
  // const [awesomeAlert, setAwesomeAlert] = useState(false);

  const redirectUrl = Linking.createURL("ProductInfo", {
    queryParams: { productId: props.route.params?.productId },
    // queryParams: { productId: props.route.params?.productId, image: pictures[0]?.downloadURL },
    // https://laptop-galaxy.web.app/ProductInfo?productId=d9Eun1MbODUc8NLUaIK0
  });

  const { confirm } = antdModal;
  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure that you want to delete this Ad?',
      icon: <ExclamationCircleFilled />,
      content: 'This can NOT be undone',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() { deleteEntireFirebaseAd() },
      // onCancel() { console.log('Cancel');},
    });
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection("Ads")
      .doc(props.route.params?.productId)
      .onSnapshot((snapshot) => {
        setproduct(snapshot.data());
        setPictures(snapshot.data()?.productImageList)
        // console.log(snapshot.data()?.Address.substring(0, snapshot.data()?.Address.indexOf(",")))
      });

    firebase.firestore()
      .collection("Ads")
      .doc(props.route.params?.productId)
      .collection("likes")
      .doc(firebase.auth().currentUser?.uid)
      .onSnapshot((snapshot) => {
        let currentUserLike = false;
        if (snapshot.exists) {
          currentUserLike = true;
        }
        setCurrentUserLike(currentUserLike)
      })

    if (firebase.auth().currentUser?.uid) {
      firebase
        .firestore()
        .collection("Users")
        .doc(firebase.auth().currentUser?.uid)
        .get()
        .then((snapshot) => {
          setUser(snapshot.data());
        });
    }

    if (product?.adOwner?.uid) {
      firebase
        .firestore()
        .collection("Users")
        .doc(product?.adOwner?.uid)
        .onSnapshot((snapshot) => {
          setUserB(snapshot.data());
        });
    }
  }, [props, combinedId]);

  // const text = 
  const handleOnPress = () => {
    if (firebase.auth().currentUser?.uid === product.adOwner?.uid) {
      props.navigation.navigate("Edit", {
        productId: props.route.params?.productId,
      });
    } else if (firebase.auth().currentUser?.uid === undefined) {
      // alert("you have to sign in");
      openNotificationWithIcon('info', 'You are not logged in', 'You have to log in first in order to contact the seller')
    } else if (user && user.uid != product.adOwner?.uid) {
      // handleSelect();
      props.navigation.navigate("Chat", {
        userID: product.adOwner?.uid,
        // product: 'Can you tell me more about that product ?\n' + product.ShortDescription + '\n' + redirectUrl
        product: 'Can you tell me more about that product ?' + '\n' + product.ShortDescription + '\n' + redirectUrl
        // product: `Can you tell me more about that product ?
        // ${product.ShortDescription}
        // ${redirectUrl}`
      });
    } else {
      console.log("smth wrong");
    }
  };



  // const handleSelect = async () => {
  //   try {
  //     const res = await getDoc(doc(db, "chats", combinedId));

  //     if (!res.exists()) {
  //       await setDoc(doc(db, 'chats', combinedId), { messages: [] })

  //       await updateDoc(doc(db, "userChats", user?.uid), {
  //         [combinedId + ".userInfo"]: {
  //           uid: product.adOwner?.uid,
  //           displayName: product.adOwner?.displayName,
  //           photoURL: product.adOwner?.photoURL,
  //         },
  //         [combinedId + ".date"]: serverTimestamp(),
  //       });

  //       await updateDoc(doc(db, "userChats", product.adOwner?.uid), {
  //         [combinedId + ".userInfo"]: {
  //           uid: user?.uid,
  //           displayName: user?.displayName,
  //           photoURL: user?.photoURL,
  //         },
  //         [combinedId + ".date"]: serverTimestamp(),
  //       });
  //     }

  //   } catch (error) { console.log(error) }
  // };

  // const handleSelect = async () => {
  //   setloading(true);
  //   const res = await getDoc(doc(db, "chats", combinedId));

  //   if (!res.exists()) {
  //     await updateDoc(doc(db, "userChats", user?.uid), {
  //       [combinedId + ".userInfo"]: {
  //         uid: product.adOwner?.uid,
  //         displayName: product.adOwner?.displayName,
  //         photoURL: product.adOwner?.photoURL,
  //       },
  //       [combinedId + ".date"]: serverTimestamp(),
  //     });

  //     await updateDoc(doc(db, "userChats", product.adOwner?.uid), {
  //       [combinedId + ".userInfo"]: {
  //         uid: user?.uid,
  //         displayName: user?.displayName,
  //         photoURL: user?.photoURL,
  //       },
  //       [combinedId + ".date"]: serverTimestamp(),
  //     });
  //   }
  //   setloading(false);
  // };

  const handleShare = async () => {
    // const redirectUrl = Linking.createURL('path/into/app', {
    const redirectUrl = Linking.createURL("ProductInfo", {
      queryParams: { productId: props.route.params?.productId },
      // https://laptop-galaxy.web.app/ProductInfo?productId=d9Eun1MbODUc8NLUaIK0
    });
    // console.log(redirectUrl);

    Sharing.shareAsync(redirectUrl, {
      dialogTitle: "Share Product Of Lap-Market",
    });

    if (Sharing.isAvailableAsync() === true) {
      // console.log("Sharing.isAvailableAsync() === true");
    }
    if (Sharing.isAvailableAsync() === false) {
      // console.log("Sharing.isAvailableAsync() === false");
    }

    // onPress = {() => { Linking.openURL(`viber://chat?number=0123456789`); }

    // const onShare = async () => {
    // try {
    //   const result = await Share.share({
    //     // message:
    //     //   'React Native | A framework for building native apps using React',
    //     // url: redirectUrl,
    //     message: ('Share Product Of Lap-Market: ' + "\n" + redirectUrl)
    //   });
    //   console.log(result)
    //   if (result.action === Share.sharedAction) {
    //     if (result.activityType) {
    //       // shared with activity type of result.activityType
    //       console.log('shared with activuity type of', result.activityType)
    //     } else {
    //       // shared
    //     }
    //   } else if (result.action === Share.dismissedAction) {
    //     // dismissed
    //     console.log('dismissed')
    //   }
    // } catch (error) {
    //   console.log(error.message);
    //   // alert(error.message);
    // }
    // };
  };

  const renderImage = ({ source, style }) => {
    return (
      <Image
        source={{
          uri: source?.uri
          // , priority: 'high'
        }}
        style={style}
        resizeMode="contain"
        indicator={renderLoading}
      />
    )
  }

  const renderLoading = () => {
    return (<ActivityIndicator color={'white'} size={'large'} />)
  }

  const onLikePress = (userId, postId, item) => {
    // item.likesCount += 1;
    setCurrentUserLike(true)
    firebase.firestore()
      .collection("Ads")
      .doc(props.route.params?.productId)
      .collection("likes")
      .doc(firebase.auth().currentUser.uid)
      .set({})

    const newProduct = {
      ...product,
      LikeTime: firebase.firestore.FieldValue.serverTimestamp()
    }

    firebase.firestore()
      .collection("likes")
      .doc(firebase.auth().currentUser.uid)
      .collection('userLikes')
      .doc(props.route.params?.productId)
      .set(newProduct)
    // .set(product,
    //   {
    //     'LikeTime':
    //       firebase.firestore.FieldValue.serverTimestamp()
    //   }
    // )
    // .then()
    // .then()
    // props.sendNotification(user.notificationToken, "New Like", `${props.currentUser.name} liked your post`, { type: 0, postId, user: firebase.auth().currentUser.uid })

  }


  const onDislikePress = (userId, postId, item) => {
    // item.likesCount -= 1;
    setCurrentUserLike(false)
    firebase.firestore()
      .collection("Ads")
      .doc(props.route.params?.productId)
      .collection("likes")
      .doc(firebase.auth().currentUser.uid)
      .delete()

    firebase.firestore()
      .collection("likes")
      .doc(firebase.auth().currentUser.uid)
      .collection('userLikes')
      .doc(props.route.params?.productId)
      .delete()
  }

  const pickOnMapHandler = () => {

    try {
      fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        product.Address +
        // product.Location.lat +
        // "," +
        // product.Location.lng +
        "&key=AIzaSyCEOCYVcrRh3fdxe199TzLIuRDE5lgBmuY"
      )
        // fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + location.coords.latitude + ',' + location.coords.longitude + '&key=AIzaSyCZ_l_v1gVoj9mHGOrL_ugNQLiCioZK4hs')
        .then((response) => response.json())
        .then((responseJson) => {

          // window.open(`https://maps.google.com/maps?q=LocationName@${product.Location.lat},${product.Location.lng}&iwloc=A`)
          // window.open(`https://www.google.com/maps/preview/@-15.623037,18.388672,8z`)
          // window.open(`https://www.google.com/maps/search/?api=1&center=${product.Location.lat},${product.Location.lng}&map_action=map`)
          // window.open(`https://maps.google.com/maps?f=q&amp;saddr=${product.Location.lat},${product.Location.lng}&amp;source=s_d&amp;hl=en&amp;z=15&amp;output=embed`)
          // window.open(`https://maps.google.com/?ll=${product.Location.lat},${product.Location.lng}`)
          // window.open("https://maps.google.com?q=" + product.Location.lat + "," + product.Location.lng) + 'api=1&map_action=map';
          // window.open(`https://maps.google.com/?ll=29.84215129999999,31.3333537`)
          window.open(`https://maps.google.com/?ll=${responseJson?.results[0]['geometry']['location'].lat},${responseJson?.results[0]['geometry']['location'].lng}&z=13`)

          // addressCoordinates = responseJson?.results[0]['geometry']['location']
          // console.log(responseJson?.results[0]['geometry']['location']);
        });

    } catch (err) {
      alert(
        // Alert.alert(
        "Could not fetch location!",
        "Please try again.",
        [{ text: "Okay" }]
      );
    }
  };



  // var geocoder = new google.maps.Geocoder();
  // geocoder.geocode({
  //     "address": inputAddress
  // }, function(results) {
  //     console.log(results[0].geometry.location); //LatLng
  // });

  const deleteEntireFirebaseAd = () => {
    firebase.firestore()
      .collection('Users')
      .doc(firebase.auth().currentUser.uid)
      .collection('Ads')
      .doc(props.route.params?.productId)
      .delete()
    firebase.firestore()
      .collection('Ads')
      .doc(props.route.params?.productId)
      .delete()

    deleteFolder(`Ads/${firebase.auth().currentUser?.uid}/${props.route.params?.productId}`)
    props.navigation.navigate('Home')
    openNotificationWithIcon('success', 'Deleted', 'Your ad is successfully deleted')
    // alert('Deleted')
    // firebase.storage().ref(`Ads/${firebase.auth().currentUser?.uid}/${route.params?.productId}`).listAll().then()
    // setDeletedItems()sfgfsdh
    // deleteItemsFromFirebaseStorage()
  }

  function deleteFolder(path) {
    const ref = firebase.storage().ref(path);
    ref.listAll()
      .then(dir => {
        dir.items.forEach(fileRef => deleteFile(ref.fullPath, fileRef.name));
        dir.prefixes.forEach(folderRef => deleteFolder(folderRef.fullPath))
      })
      .catch(error => console.log(error));
  }

  function deleteFile(pathToFile, fileName) {
    const ref = firebase.storage().ref(pathToFile);
    const childRef = ref.child(fileName);
    childRef.delete()
    // console.log(fileName, pathToFile)
  }

  const slidesToShow = pictures?.length >= 3 ? 3 : pictures?.length

  // const carouselWidth =
  //   pictures?.length >= 3 ? screenSize.width / 6.1 :
  //     pictures?.length == 2 ? '80%' :
  //       pictures?.length == 1 ? '50%' :
  //         '100%'

  // pictures?.length == 1 ? screenSize.height / 2.8 :
  // pictures?.length == 2 ? screenSize.width / 6.4 :

  // const carouselWidth =
  //   // pictures?.length >= 4 ? screenSize.width / 2:
  //   pictures?.length >= 3 ? screenSize.width / 2 :
  //     pictures?.length == 2 ? screenSize.width / 2.5 :
  //       pictures?.length == 1 ? screenSize.height / 4 :
  //         '100%'

  const ratio = (number) => {
    return (
      (screenSize.height / number) + (screenSize.width / (number * 2.75))
    )
  }

  const ratio2 = (number) => {
    return (
      (screenSize.height / number) + (screenSize.width / (number * 4))
    )
  }


  if (product) {
    return (
      // <View style={{flex: 1}}>
      <View style={{
        // marginHorizontal: screenSize.isWide ? screenSize.width / 7.9 : 0,
        // overflow: 'hidden',
        flex: 1,
        // backgroundColor: 'red',
        // justifyContent: 'center',
        // alignItems: 'center'
        // width: screenSize.isWide ? screenSize.width / 2 : '100%'
        // alignSelf: 'center',
      }}>
        {/* <View style={{ flex: 1, width: screenSize.isWide ? screenSize.width / 2 : '100%', alignSelf: 'center', overflow: 'hidden', backgroundColor: 'red' }}> */}
        <ScrollView style={{
          overflowY: "visible",
          overflowX: "hidden",
          paddingHorizontal: screenSize.isWide ? screenSize.width / 4.3 : 0,
          // overflow: screenSize.isWide ? 'visible' : 'hidden',
          // alignSelf: 'center'
          // backgroundColor: 'aqua',
        }}
          showsVerticalScrollIndicator={false}
        >

          {loading ? (
            <ActivityIndicator size="small" color="red" />
          ) : null}


          {pictures?.length != 0 ?
            <View
              style={{
                alignSelf: screenSize.isWide ? 'center' : undefined,
                width: '100%',
                height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                justifyContent: screenSize.isWide ? 'center' : undefined,
                // width: screenSize.isWide ? Number(carouselWidth) : '100%',
                // backgroundColor: 'yellow'
              }}>

              <Carousel
                infinite={false}
                ref={ref}
                slidesToShow={screenSize.isWide ? slidesToShow : 1}
                style={{
                  height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                  alignSelf: 'center',
                  // width: '100%',
                  // height: '100%',
                  // backgroundColor: 'green'
                  // justifyContent: 'space-around',
                  // alignItems: 'center',
                  // alignContent: 'space-around'
                }}
                arrows={true}
                dots={true}
                waitForAnimate={true}
                draggable={true}
                swipeToSlide
              // slidesPerRow={4}
              >

                {product.productImageList?.map((item, index) => {
                  return (
                    <View
                      key={index}
                    // style={{
                    //   width: screenSize.isWide
                    //     ? screenSize.width / 3
                    //     : screenSize.width,
                    //   padding: 2,
                    //   maxHeight: screenSize.width,
                    // }}
                    >
                      <TouchableOpacity onPress={() => { setDialog(index) }}>
                        <Image
                          source={item.downloadURL}
                          style={{
                            borderRadius: 3,
                            alignSelf: 'center',
                            alignItems: "center",
                            justifyContent: "center",
                            borderBottomRightRadius: 20,
                            borderBottomLeftRadius: 20,
                            height: screenSize?.isWide ? screenSize?.height / 3 : screenSize?.height / 2.1,
                            // width: screenSize.isWide ? carouselWidth : '100%',
                            width: screenSize?.isWide ?
                              pictures?.length >= 3 ? screenSize?.width / 6.1 :
                                pictures?.length == 2 ? '80%' :
                                  pictures?.length == 1 ? '50%' :
                                    '100%' : '100%',

                            resizeMode: 'cover',
                            aspectRatio: 1,
                            borderRightWidth: 2,
                            borderLeftWidth: 2,
                            borderBottomWidth: 4,
                            borderColor: '#c9c9c9',
                            // width: screenSize.isWide ? screenSize.height / 3 : '100%',
                            // width: screenSize.isWide ? screenSize.ratio(6) : '100%',
                            // width: screenSize.isWide ? (screenSize.width / 3.5) / slidesToShow : '100%',
                            // height: screenSize.isWide ? screenSize.height / 3 : screenSize.height / 2.1,
                            // width: screenSize.isWide ? screenSize.width / 6.1 : '100%',
                            // backgroundColor: 'blue'
                          }} />
                      </TouchableOpacity>
                    </View>
                  )
                })}
              </Carousel>
              {/* </AntdImage.PreviewGroup> */}
            </View>
            : null}

          <View
            style={{
              paddingHorizontal: 12,
              marginTop: 19,
              // paddingHorizontal: 12,
              // marginTop: 7,
              // backgroundColor: 'green'
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                // backgroundColor: 'blue'
              }}
            >
              <Text
                style={{
                  fontSize: ratio(56),
                  fontWeight: "600",
                  opacity: 0.8
                  // color: "#1b1b1b",
                }}
              >
                {product.ShortDescription}
              </Text>

              <Text style={{
                fontSize: ratio(70),
                opacity: 0.8,
                textAlign: 'center',
                // maxWidth: '20%',
                // backgroundColor: 'red'
              }}>
                {product.creation && product.creation.toDate().toDateString()}
              </Text>
            </View>





            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: 8,
                // backgroundColor: 'red'
                // marginVertical: 15,
              }}
            >
              <View >
                <Text
                  style={{
                    fontSize: (screenSize.height / 38) + (screenSize.width / 152),
                    // color: COLOURS.black,
                    // #007d70
                    color: '#004c54',
                    // color: '#007d70#00363c',
                    fontWeight: "800",
                    letterSpacing: 1,
                    lineHeight: 20,
                  }}
                >
                  EGP {product.Price}
                  {/* &#65284; {product.Price}.0 */}
                </Text>
                {/* <Text>
              Tax Rate 2%~ &#65284;
              {(product.Price * 2) / 100} (&#65284;
              {(product.Price * 102) / 100})
            </Text> */}
              </View>

              <View style={{
                flexDirection: "row",
                alignItems: "center",
                // backgroundColor: 'green'
                // marginVertical: 10,
              }}>
                <TouchableOpacity onPress={handleShare}>
                  <Ionicons
                    size={ratio(51)}
                    name="link-outline"
                    style={{
                      // fontSize: 24,
                      color: COLOURS.blue,
                      backgroundColor: COLOURS.blue + 10,
                      padding: 8,
                      borderRadius: 100,
                      marginRight: 5
                    }}
                  />
                </TouchableOpacity>



                {currentUserLike ?
                  (<Entypo name="heart" size={ratio(30)} color="red" onPress={() => onDislikePress(
                    // user.uid, item.id, item
                  )} />) :
                  (<Feather name="heart" size={ratio(33)} color="red" onPress={() => onLikePress(
                    // user.uid, item.id, item
                  )} />)}
              </View>
            </View>



            <TouchableOpacity
              onPress={pickOnMapHandler}
              style={{
                marginTop: screenSize.ratio(40),
                padding: 10,
                borderRadius: 8,
                backgroundColor: '#dfdfdf'
              }}
            >

              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  // marginTop: screenSize.ratio(40)
                  // borderBottomColor: COLOURS.backgroundLight,
                  // borderBottomWidth: 1,
                  // paddingBottom: 8,
                  // marginTop: 35
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    width: "80%",
                    alignItems: "center",
                  }}
                >
                  <View
                    style={{
                      color: COLOURS.blue,
                      backgroundColor: "#abbbe7",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 12,
                      borderRadius: 100,
                      marginRight: 10,
                    }}
                  >
                    <Entypo
                      name="location-pin"
                      style={{
                        fontSize: ratio(55),
                        color: COLOURS.blue,
                      }}
                    />
                  </View>
                  <Text style={{ fontSize: ratio(65) }}>
                    {product.Address?.replace(",", ".\n")}
                    {/* {product.Address?.replaceAll(",", ".\n").replace("Egypt", "")} */}
                  </Text>
                </View>
                <Entypo
                  name="chevron-right"
                  style={{
                    fontSize: ratio(36),
                    color: COLOURS.backgroundDark,
                  }}
                />
              </View>

              <View style={{
                borderRadius: 5,
                overflow: 'hidden',
                borderColor: "#ccc",
                borderWidth: 2,
                height: 130,
                width: '100%',
                marginTop: 4
                // aspectRatio: 8 / 1,
              }}>
                <MapPreview
                  style={{ width: '100%', height: '100%' }}
                  location={product.Location}
                  onPress={pickOnMapHandler}
                ></MapPreview>
              </View>
            </TouchableOpacity >

            <View style={{
              flexDirection: 'row',
              borderRadius: 5,
              width: '100%',
              borderWidth: 1,
              borderColor: '#a4a4a4',
              marginTop: 20,
              overflow: 'hidden',
              height: screenSize.ratio(24),
            }}>
              <View style={{ width: '33.3%' }}>
                <View style={{
                  backgroundColor: '#dfdfdf',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '50%'
                  // width: '25%',
                }}>
                  <Text style={{ fontSize: screenSize.ratio(65), fontWeight: '600', color: '#6b6b6b' }}>
                    Condition
                  </Text>
                </View>
                <View style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '50%'
                }}>
                  <Text style={{ fontSize: screenSize.ratio(70), fontWeight: '600' }}>
                    {product.condition?.toUpperCase()}
                  </Text>
                </View>
              </View>


              <View style={{ width: '33.3%' }}>
                <View style={{
                  backgroundColor: '#dfdfdf',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderColor: '#a4a4a4',
                  height: '50%'
                }}>
                  <Text style={{ fontSize: screenSize.ratio(65), fontWeight: '600', color: '#6b6b6b' }}>
                    Type
                  </Text>
                </View>
                <View style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRightWidth: 1,
                  borderLeftWidth: 1,
                  borderColor: '#a4a4a4',
                  height: '50%'
                }}>
                  <Text style={{ fontSize: screenSize.ratio(70), fontWeight: '600' }}>
                    {product.ProductType == 'gamingLaptop' ? 'Gaming Laptop' :
                      product.ProductType == 'businessLaptop' ? 'Business Laptop' :
                        product.ProductType == 'workstation' ? 'Workstation' :
                          product.ProductType
                    }
                  </Text>
                </View>
              </View>


              <View style={{ width: '33.3%' }}>
                <View style={{
                  backgroundColor: '#dfdfdf',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '50%'
                }}>
                  <Text style={{ fontSize: screenSize.ratio(65), fontWeight: '600', color: '#6b6b6b' }}>
                    Availablity
                  </Text>
                </View>
                <View style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '50%'
                }}>
                  <Text style={{ fontSize: screenSize.ratio(70), fontWeight: '600', color: product.isAvailable ? '#004b06' : 'red' }}>
                    {product.isAvailable ? 'Still Available' : 'Sold'}
                  </Text>
                </View>
              </View>
            </View>






            {/* <View style={{ marginVertical: 14, padding: 12.5, borderRadius: 8, backgroundColor: '#e2e2e2' }}> */}

            {/* <Text
              style={{
                fontSize: ratio(56),
                color: COLOURS.black,
                fontWeight: '900',
                letterSpacing: 1,
                // marginTop: 20
              }}>
              Description
            </Text>

            <Text
              style={{
                fontSize: ratio(63),
                color: COLOURS.black,
                fontWeight: "400",
                marginTop: 10,
                letterSpacing: 0.1,
                includeFontPadding: true,
                // direction
                // start
                // verticalAlign
              }}
            >
              {product.LongDescription}
            </Text> */}


            {product?.LongDescription !== '' &&
              <View style={{ marginTop: 20 }}>
                <Text
                  style={{
                    fontSize: ratio(56),
                    color: COLOURS.black,
                    fontWeight: '900',
                    letterSpacing: 1,
                    // marginTop: 20
                  }}>
                  Description
                </Text>

                <Text
                  style={{
                    fontSize: ratio(63),
                    color: COLOURS.black,
                    fontWeight: "400",
                    marginTop: 10,
                    letterSpacing: 0.1,
                    includeFontPadding: true,
                    // direction
                    // start
                    // verticalAlign
                  }}
                >
                  {product.LongDescription}
                </Text>
              </View>}






            {/* <View style={{
              flexDirection: 'row',
              borderRadius: 5,
              width: '100%',
              height: screenSize.ratio(30),
              alignSelf: 'center',
              borderWidth: 1,
              borderColor: '#a4a4a4',
              overflow: 'hidden',
              marginTop: 30,
              // marginTop: 5,
              // marginBottom: 20,
            }}>
              <View style={{ backgroundColor: '#dedede', width: '25%', alignItems: 'center', justifyContent: 'center', }}>
                <Text style={{ fontSize: screenSize.ratio(65), fontWeight: '600', color: '#6b6b6b' }}>
                  Condition
                </Text>
              </View>
              <View style={{ width: '25%', alignItems: 'center', justifyContent: 'center', borderLeftWidth: 1, borderColor: '#a4a4a4' }}>
                <Text style={{ fontSize: screenSize.ratio(70), fontWeight: '600' }}>
                  {product.condition?.toUpperCase()}
                </Text>
              </View>

              <View style={{ backgroundColor: '#dedede', width: '25%', alignItems: 'center', justifyContent: 'center', borderLeftWidth: 1, borderRightWidth: 1, borderColor: '#a4a4a4' }}>
                <Text style={{ fontSize: screenSize.ratio(65), fontWeight: '600', color: '#6b6b6b' }}>
                  Type
                </Text>
              </View>
              <View style={{ width: '25%', alignItems: 'center', justifyContent: 'center', }}>
                <Text style={{ fontSize: screenSize.ratio(70), fontWeight: '600' }}>
                  {product.ProductType == 'gamingLaptop' ? 'Gaming Laptop' :
                    product.ProductType == 'businessLaptop' ? 'Business Laptop' :
                      product.ProductType == 'workstation' ? 'Workstation' :
                        product.ProductType
                  }
                </Text>
              </View>
            </View> */}



            <Text style={{
              fontSize: ratio(56),
              color: COLOURS.black,
              fontWeight: '900',
              letterSpacing: 1,
              // borderTopColor: '#bdbdbd',
              // borderTopWidth: 1,
              // marginTop: 5,
              marginTop: 35,
            }}>
              Seller Information
            </Text>

            <TouchableOpacity
              onPress={() => {
                props.navigation.navigate("MyAccount", {
                  profileID: product.adOwner?.uid,
                });
              }}
              style={{
                alignItems: "center",
                flexDirection: "row",
                marginTop: 7,
                marginBottom: 10
              }}
            >
              <Image
                source={{ uri: userB?.photoURL ? userB?.photoURL : product.adOwner?.photoURL }}
                style={{
                  height: screenSize.height / 13,
                  width: screenSize.height / 13,
                  borderRadius: 300,
                }}
              />
              <View style={{ marginHorizontal: 10 }}>
                <Text style={{ fontSize: 20, fontWeight: "700", opacity: 0.8 }}>
                  {userB?.displayName ? userB?.displayName : product.adOwner?.displayName}
                </Text>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={{ fontSize: 14, fontWeight: "700", color: "#0a4f57", marginRight: 2, opacity: 0.8 }}>
                    See Profile
                  </Text>
                  <Entypo name="chevron-small-right" size={19} color="#0a4f57" />
                </View>
              </View>
            </TouchableOpacity>
          </View>



          {user?.uid !== product.adOwner?.uid &&
            userB?.phoneNumber && userB?.contactMethods?.phoneNumber == true ? (
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
                paddingVertical: 2,
                justifyContent: 'space-between',
                marginVertical: 15,
                marginHorizontal: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  // const phoneNumber = `+2${product.adOwner?.phoneNumber}`
                  // const whatsappApiURL = `https://api.whatsapp.com/send?phone=+2${userB?.phoneNumber}`
                  // const whatsappApiURL = `whatsapp://send?text=I Saw This On Lap-Market&phone=+2${userB?.phoneNumber}`
                  // const whatsappApiURL = `https://whatsapp://send?phone=+2${userB?.phoneNumber}`
                  const text = 'Can you tell me more about the product that you published on Lap Market ?\n' + `(${product.ShortDescription})` + '\n' + redirectUrl
                  const whatsappApiURL =
                    // "https://api.whatsapp.com/send/?phone=2" + userB?.phoneNumber +
                    // "&text=Can you tell me more about your product that you published on Lap Market ?\r\n" + "\r\n"
                    // + product.ShortDescription + "\r\n"
                    // + redirectUrl

                    `https://api.whatsapp.com/send/?phone=2${userB?.phoneNumber}&text=${text}`

                  // ${`\n`}${product.ShortDescription}${`\n`}${redirectUrl}

                  // `https://api.whatsapp.com/send/?phone=2${userB?.phoneNumber}&text=Can you tell me more about your product that you published on Lap Market ? ${`\n`} ${product.ShortDescription} ${`\n`} ${redirectUrl}`

                  // `https://api.whatsapp.com/send/?phone=2${userB?.phoneNumber}&text=Can you tell me more about your product that you published on Lap Market ?${product.ShortDescription}${redirectUrl}&type=phone_number&app_absent=0`

                  // Linking.openURL('https://whatsapp://send?text=hello&phone=+201286717453')
                  Linking.openURL(encodeURI(whatsappApiURL));
                  // console.log(encodeURI(whatsappApiURL))
                }}
                // <TouchableOpacity onPress={() => { Linking.openURL(`whatsapp://send?text=Hello&phone=${product.adOwner?.phoneNumber}`) }}
                style={{
                  height: 47,
                  width: '32.5%',
                  backgroundColor: "#133337",
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                  <Ionicons name="logo-whatsapp" size={ratio(36)} color="green" />
                  <Text style={{ fontSize: ratio(59), fontWeight: "700", color: "green", marginLeft: 4 }}>
                    {/* {product.adOwner?.phoneNumber} */}
                    WhatsApp
                  </Text>
                </View>
              </TouchableOpacity>

              <TouchableOpacity
                onPress={() => {
                  Linking.openURL(`tel:+2${userB?.phoneNumber}`);
                }}
                style={{
                  height: 47,
                  width: '32.5%',
                  backgroundColor: "#133337",
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                  <Feather name="phone-call" size={ratio(42)} color={"#FFF"} />
                  <Text style={{ fontSize: ratio(48), fontWeight: "500", color: "#FFF", marginHorizontal: 9 }}>
                    Call
                  </Text>
                </View>
              </TouchableOpacity>

              {userB?.contactMethods.chat == true ?
                <TouchableOpacity
                  onPress={() => {
                    handleOnPress();
                  }}
                  style={{
                    height: 47,
                    width: '32.5%',
                    backgroundColor: "#133337",
                    borderRadius: 10,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                    <Entypo name="chat" size={ratio(42)} color={"#FFF"} />
                    <Text style={{ fontSize: ratio(48), fontWeight: "500", color: "#FFF", marginHorizontal: 9 }}>
                      Chat
                    </Text>
                  </View>
                </TouchableOpacity> : null}

            </View>
          ) : (
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
                paddingVertical: 2,
                justifyContent: 'space-between',
                marginVertical: 15,
                marginHorizontal: 10,
              }}
            >
              <TouchableOpacity
                onPress={() => { handleOnPress(); }}
                style={{
                  height: 47,
                  width: '32.5%',
                  backgroundColor: "#133337",
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                  <Feather name="edit" size={ratio(42)} color="#FFF" />
                  <Text style={{ fontSize: ratio(48), fontWeight: "500", color: '#fff', marginHorizontal: 9 }}>
                    Edit
                  </Text>
                </View>
              </TouchableOpacity>


              <Animatable.View
                style={{
                  width: '32.5%',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginVertical: 10
                }}
                ref={rubberBandAnimeRef}
              >
                {product.isAvailable ?
                  <TouchableOpacity
                    onPress={() => {
                      firebase.firestore().collection('Ads').doc(product.id).update('isAvailable', false)
                      firebase.firestore().collection('Users').doc(user.uid).collection('Ads').doc(product.id).update('isAvailable', false)
                      rubberBandAnimeRef.current.bounce(800)
                    }}
                    style={{
                      height: 47,
                      width: '100%',
                      backgroundColor: "#133337",
                      borderRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                      <MaterialCommunityIcons name="hand-coin" size={ratio(32)} color={"#FFF"} />
                      <Text numberOfLines={2} style={{ fontSize: ratio2(64), fontWeight: "500", color: "#FFF", marginHorizontal: 7, textAlign: 'center' }}>
                        Mark{'\n'}As Sold
                      </Text>
                    </View>
                  </TouchableOpacity>
                  :
                  <TouchableOpacity
                    onPress={() => {
                      firebase.firestore().collection('Ads').doc(product.id).update('isAvailable', true)
                      firebase.firestore().collection('Users').doc(user.uid).collection('Ads').doc(product.id).update('isAvailable', true)
                      rubberBandAnimeRef.current.wobble(800)
                      // console.log(rubberBandAnimeRef.current)
                    }}
                    style={{
                      height: 47,
                      width: '100%',
                      backgroundColor: "#133337",
                      borderRadius: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                      <MaterialCommunityIcons name="backup-restore" size={ratio(32)} color="#fff"
                      />
                      <Text numberOfLines={2} style={{ fontSize: ratio2(64), fontWeight: "500", color: "#FFF", marginHorizontal: 6, textAlign: 'center' }}>
                        Mark As{'\n'}Available
                      </Text>
                    </View>
                  </TouchableOpacity>
                }
              </Animatable.View>


              <TouchableOpacity
                onPress={showDeleteConfirm}
                // onPress={() => { setAwesomeAlert(oldState => !oldState) }}
                style={{
                  height: 47,
                  width: '32.5%',
                  backgroundColor: "red",
                  borderRadius: 10,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                  <AntDesign name="delete" size={ratio(42)} color={"#000"} />
                  <Text style={{ fontSize: ratio(48), fontWeight: "500", color: "#000", marginHorizontal: 9 }}>
                    Delete
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
          {screenSize.isWide && product.productImageList?.length > (screenSize.width / 2) / (screenSize.width / 6) ? // Divide by 10 because I have 10 <View> items
            // {screenSize.isWide && product.productImageList?.length > (screenSize.width - screenSize.width / 11) / (screenSize.width / 7) ?
            // {product.productImageList?.length != 0 && screenSize.isWide ?
            <>
              <TouchableOpacity
                style={{
                  position: 'absolute',
                  alignItems: "center",
                  alignSelf: 'flex-start',
                  top: screenSize.ratio(12),
                  // left: '100%',
                  // paddingRight: 600,
                  // marginRight: 30
                  left: -24
                }}
                onPress={() => { ref.current?.prev(); }}
              // onPress={() => {
              //   const eachItemOffset = scrollViewWidth / props.productsLength; // Divide by 10 because I have 10 <View> items
              //   const _currentXOffset = currentXOffset - eachItemOffset;
              //   ref.current.scrollTo({ x: _currentXOffset, y: 0, animated: true })
              // }}
              >

                <AntDesign
                  name="leftcircle"
                  size={screenSize.ratio(30)}
                  color='#cacaca'
                />
              </TouchableOpacity>

              <TouchableOpacity
                style={{
                  position: 'absolute',
                  alignItems: "center",
                  alignSelf: 'flex-end',
                  top: screenSize.ratio(12),
                  right: -24
                  // left: '20%'
                }}
                onPress={() => { ref.current?.next(); }}
              // onPress={() => {
              //   const eachItemOffset = scrollViewWidth / props.productsLength; // Divide by 10 because I have 10 <View> items
              //   const _currentXOffset = currentXOffset + eachItemOffset;
              //   ref?.current?.scrollTo({ x: _currentXOffset, y: 0, animated: true })
              // }}
              >
                <AntDesign
                  name="rightcircle"
                  size={screenSize.ratio(30)}
                  color='#cacaca'
                />
              </TouchableOpacity>
            </>
            // <View style={styles.ButtonBox}>
            //   <TouchableOpacity
            //     onPress={() => { ref.current?.prev(); }} >
            //     <AntDesign
            //       name="leftcircle"
            //       size={screenSize.ratio(30)}
            //       color='#9b9b9b'
            //     />
            //   </TouchableOpacity>
            //   <TouchableOpacity
            //     onPress={() => { ref.current?.next(); }}>
            //     <AntDesign
            //       name="rightcircle"
            //       size={screenSize.ratio(30)}
            //       color='#9b9b9b'
            //     />
            //   </TouchableOpacity>
            // </View>
            : null}
        </ScrollView>

        {/* </View> */}



        <Modal
          visible={dialog !== null}
          animationType="fade"
          transparent={true}
          style={{ flex: 1, width: '100%' }}
        >
          <ImageViewer
            index={dialog}
            // renderArrowLeft={}
            flipThreshold={1000}
            imageUrls={product?.productImageList?.map((item) => { return { url: item.downloadURL } })}
            loadingRender={renderLoading}
            renderImage={renderImage}
          />

          <TouchableOpacity
            onPress={() => setDialog(null)}
            style={{
              backgroundColor: 'black',
              position: 'absolute',
              top: 10,
              left: 10,
              borderRadius: 200
            }}
          >
            <MaterialCommunityIcons
              name="close-circle"
              size={35}
              color="#52575D"
            />
          </TouchableOpacity>
        </Modal>
      </View>
    );
  }
  else {
    return <ActivityIndicator size={"large"}></ActivityIndicator>;
  }
};

export default ProductInfo;

function useStyles() {
  return StyleSheet.create({
    textInput: {
      fontSize: ScreenSize().isWide
        ? ScreenSize().width / 50
        : ScreenSize().width / 19,
      margin: 5,
      paddingLeft: 10,
      borderTopWidth: 2,
      borderBottomWidth: 2,
      borderLeftWidth: 2,
      borderRightWidth: 2,
      borderRadius: 3,
    },
    text: {
      fontSize: 24,
      fontStyle: "italic",
      paddingLeft: 13,
    },
    ButtonBox: {
      position: "absolute",
      flexDirection: "row",
      alignItems: "center",
      top: ScreenSize().ratio(12),
      justifyContent: "space-between",
      alignContent: "center",
      alignSelf: "center",
      // width: "100%",
      width: "105.5%",
    },
    zoomedImg: {
      height: 500,
      width: '100%'
    },
    imageContainer: {
      backgroundColor: 'black',
      flex: 1,
      justifyContent: 'center',
      alignItems: "center"
    }
  });
}

