import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ScrollView,
  Image,
  TextInput,
  StyleSheet,
  TouchableOpacity,
} from "react-native";
import firebase from "../firebase/firebaseConfig";
import ScreenSize from "../components/ScreenSize";
import filter from "lodash.filter";
import Carousel from 'react-native-reanimated-carousel';
import { FormControl, Modal, Button } from "native-base";
import HorizontalScrollView from "../components/HorizontalScrollView";
// import * as Animatable from 'react-native-animatable'
// import { notification } from 'antd'

// import { COLOURS } from "../components/database/Database";
// import ProductCard from "../components/ProductCard";
// import { Carousel } from 'antd';
// import { Modal } from "react-native";


// #ab66 #5c8581
// import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
// import { Pressable } from "react-native";
////////////////////////////////////////////////   Done   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// selling image order
// search
// Arrow for going back
// when avatar tapped go to profile page
// Profile page with products
// log in with email and FaceBook
// edit Ad
// edit Profile
// adding pic while email signup + add phone num
// show phone number when contact
// location Services
// zoom when image tabbed
// follow
// Save Ad
// my likes && my following
// maximum characters for title and description
// add [+2] when adding phone number
// choose contact methods && add phone number modal if adding an Ad with phone number undefined
// Activity log
// filtering system (while search)
// services [Normal Modal]
// delete specific pic in editing (IndexOf and then delete)
// ActivityIndicator (with dimming) while delete-upload-edit
// fix chat (no doc to update)
// sold and available ui
// settings  hide or show phone number - language - password
// alert / prompt to confirm reset password
// laptop condition and product type show in product info
// desktop dimentions and styling (MyAccount userB)
// fix location (citydropdown) and updating the firebase
// replace all alerts with notification (or toast)
// organize firebase storage (remove pics on ad removal)
// unread messages
// contact us
// add 60s counter before resend sms
// phone number verification [with phone number auth]
// in add screen when asking for communication methods for the first time ... make it the same ADD button for uploading.. and not to press the other ADD again
// add screen use the new horizontal scroll view cuz arrows not showing correctly
// download maps with diffrent dimentions and try 8/1 aspect ratio
///////////////////////////////////////////////   Important   ///////////////////////////////////////////////////////////////////////////////////
//drag images from antD or nativeBase to select images order
//////////////////////////////////////////////////   Not Very Important   ////////////////////////////////////////////////////////////////////////////////////
// combine all three auth methods
// when user logs in he gets notified with chats (and get notification permission like facebook)
// saved searches
// upload images with bytes transfered

// refactor with protective navigation and user data {
// https://github.com/FaridSafi/ChatApp
// https://www.youtube.com/watch?v=k4mjF4sPITE
// }

// change props names (AddScreen / productData > which is userID)
// online state
// tags in filters
// use profile picture with initial letter
// opengraph image react native preview uri whatsapp (https://stackoverflow.com/questions/50096135/include-image-when-sharing-a-link-on-whatsapp)
// blur the edges of horizontal scrollview
// use upload pic from nativebase / antd

// testing (log in & out from all pages)
// Clean Code (make components for repetative codes // less firebase query // one ads location in database)
// remake project with the latest libraries versions (one library at a time)
// notification for android + IOS (for chat and alerts)
// send images in chat
// spying
// see posts of following and trending (likes count)
// rating
// nice and responsive UI (look at a lot of react native UIs and imitate the best one)
// add footage
// chat by product not by person
// Google Maps JavaScript API with Places Library Autocomplete Address Field
///////////////////////////////////////////////////////   Notes   ////////////////////////////////////////////////////////////////////////////////////
// DesignIntoCode
// try to setup app check to fix FB Auth
// https://ant.design/components/upload
// https://docs.dndkit.com/presets/sortable
// https://www.npmjs.com/package/antd-img-crop
// https://timetoprogram.com/reactjs/
// https://docs.nativebase.io/action-sheet
// https://docs.nativebase.io/toast
// https://ant.design/components/notification
// https://ant.design/components/tag  for filters
// https://ant.design/components/qr-code

// https://www.youtube.com/watch?v=wsZ_mjXk6Hg
// Toast https://github.com/calintamas/react-n...
// Skeleton https://github.com/danilowoz/react-co...
// 7. Pager view https://github.com/callstack/react-na...
// 8. Blur view https://docs.expo.dev/versions/latest...
// 9. Calendar https://github.com/wix/react-native-c...
// 10. FlashList https://shopify.github.io/flash-list/

// https://www.youtube.com/watch?v=lPJVi797Uy0

// https://www.svgbackgrounds.com/

// export default function SafeView({ children, style }) {
//   return (
//     <SafeAreaView style={[styles.safeAreaContainer, style]}>
//       <View style={[styles.container, style]}>{children}</View>
//     </SafeAreaView>
//   );
// }

// DND
// https://codesandbox.io/p/sandbox/react-drag-and-drop-sortablelist-g205n?file=%2Fsrc%2FApp.jsx


//CONSTANTS :{
//  relativetiy betwen width and height is 2.75 (width / height)
// fontSize: (screenSize.height / 60) + (screenSize.width / 165),
// }

// https://reactnativeelements.com/docs/
// https://docs.nativebase.io/
// https://ant.design/components/overview

const HomeScreen = (props) => {
  const styles = useStyles();
  const screenSize = ScreenSize();
  const [meals, setMeals] = useState([]);
  const [gamingLaptops, setgamingLaptops] = useState([]);
  const [businessLaptops, setbusinessLaptops] = useState([]);
  const [workstations, setworkstation] = useState([]);
  const [fullMeals, setfullMeals] = useState([]);
  const [searchQuery, setsearchQuery] = useState("");
  const [searchAds, setsearchAds] = useState([]);
  const [changingAds, setChangingAds] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [modalText, setModalText] = useState('')
  const [modalDescription, setModalDescription] = useState('')
  const [modalImage, setModalImage] = useState(0)
  const [followingAds, setfollowingAds] = useState([]);
  const [animation, setAnimation] = useState('slideOutUp');
  const [animationPressable, setAnimationPressable] = useState('fadeIn');

  // const [api, contextHolder] = notification.useNotification();

  // const currentFlatlistIndex = useRef(null)
  // const flatListRef = useRef(FlatList);
  // const [value, setValue] = useState(0);

  // const isPhoneDimentions = Dimensions.get('window').
  // const [accessory, setAccessory] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [other, setother] = useState([]);


  useEffect(() => {
    firebase
      .firestore()
      .collection("Ads")
      .orderBy("creation", "desc")
      .onSnapshot((snapshot) => {
        setMeals(snapshot.docs.map((doc) => doc.data()));
        setsearchAds(snapshot.docs.map((doc) => doc.data()));
        setfullMeals(snapshot.docs.map((doc) => doc.data()));
      });
    firebase
      .firestore()
      .collection("Ads")
      .where("ProductType", "==", "gamingLaptop")
      .orderBy("creation", "desc")
      .onSnapshot((snapshot) => {
        setgamingLaptops(snapshot.docs.map((doc) => doc.data()));
      });
    firebase
      .firestore()
      .collection("Ads")
      .where("ProductType", "==", "businessLaptop")
      .orderBy("creation", "desc")
      .onSnapshot((snapshot) => {
        setbusinessLaptops(snapshot.docs.map((doc) => doc.data()));
      });
    firebase
      .firestore()
      .collection("Ads")
      .where("ProductType", "==", "workstation")
      .orderBy("creation", "desc")
      .onSnapshot((snapshot) => {
        setworkstation(snapshot.docs.map((doc) => doc.data()));
      });
  }, []);



  const handleSearch = (query) => {
    setsearchQuery(query);
    const formattedQuery = query?.toLowerCase();
    const filteredData = filter(changingAds, (user) => {
      return contains(user, formattedQuery);
    });
    setsearchAds(filteredData);
    // console.log(filteredData)

  };

  const contains = ({ ShortDescription, LongDescription, adOwner }, query) => {
    const { name } = adOwner;
    if (
      ShortDescription?.toLowerCase().includes(query) ||
      LongDescription?.toLowerCase().includes(query) ||
      name?.toLowerCase().includes(query)
    ) {
      return true;
    }
    return false;
  };

  const imgs = [
    require('../assets/certified.jpg'),
    require('../assets/delivery.jpg'),
    require('../assets/scam.jpg'),
  ]

  const carouselImages = screenSize.isWide ? [
    require('../assets/banner1.jpg'),
    require('../assets/banner2.jpg'),
    require('../assets/banner3.jpg'),
    require('../assets/banner4.jpg'),
    // require('../assets/banner5.jpg'),
    // require('../assets/banner6.jpg'),
  ] : [
    require('../assets/phoneBanner1.jpg'),
    require('../assets/phoneBanner2.jpg'),
    require('../assets/phoneBanner3.jpg'),
  ]


  // const AnimatedPressable = Animatable.createAnimatableComponent(Pressable)

  // const toggleSheet = () => {
  //   setModalVisible(!modalVisible);
  // }

  return (
    <View style={{ flex: 1 }}>
      {/* <LinearGradient
        style={{ flex: 1 }}
        colors={["#F3F0F4", "#F3F0F4", "#F3F0F4", "#F3F0F4"]}
      // colors={["#F3F0F4", "#F4F7ED", "#EFF5F5", "#F1EEF6"]}
      > */}


      {/* {contextHolder} */}


      {/* {modalVisible && (
        <>
          <AnimatedPressable animation={animationPressable} duraton="600" style={styles.backdrop} onPress={() => {
            setAnimation('slideOutDown')
            setAnimationPressable('fadeOut')
            setTimeout(toggleSheet, 650)
          }} />

          <Animatable.View style={styles.sheet} animation={animation} duraton="800" easing='ease-in-out-expo'>
            <View>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Adding...
              </Text>
            </View>
          </Animatable.View>
        </>
      )} */}



      {/* {modalVisible && (
        <>
          <AnimatedPressable animation={animationPressable} duraton="600" style={styles.backdrop} onPress={() => {
            setAnimation('slideOutDown')
            setAnimationPressable('fadeOut')
            setTimeout(toggleSheet, 650)
          }} />

          <Animatable.View style={styles.sheet} animation={animation} duraton="600" easing='ease-out-back'>

            <View>
              <Text
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Adding...
              </Text>
            </View>
          </Animatable.View>
        </>
      )} */}













      <Modal
        position='absolute'
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        // height={screenSize.height * 3}
        style={{ flex: 1, height: '100%', position: 'absolute', ...StyleSheet.absoluteFillObject, }}
      // width={screenSize.isWide ? screenSize.width / 2 : screenSize.width / 1.2} 
      >
        <Modal.Content
        // maxWidth="400px"
        >
          <Modal.CloseButton />
          <Modal.Header>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: 10 }}>
            </View>
          </Modal.Header>
          <Modal.Body>
            <FormControl>
              <Image resizeMode="contain" style={{ alignSelf: 'center', width: (screenSize.height / 12) + (screenSize.width / 12), height: (screenSize.height / 10) + (screenSize.width / 16) }} source={imgs[modalImage]} />

              <Text style={{ fontSize: (screenSize.height / 80) + (screenSize.width / 80), fontWeight: '800', alignSelf: 'center' }}>{modalText}
              </Text>

              <Text style={{
                fontSize: (screenSize.height / 60) + (screenSize.width / 165),
                // fontSize: (screenSize.height / 65) + (screenSize.width / 130),
                // fontSize: (screenSize.height + screenSize.width) / 70
                marginTop: 50,
                fontWeight: '600', alignSelf: 'center'
              }}>{modalDescription}
              </Text>

            </FormControl>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group space={2}>
              <Button variant="ghost" colorScheme="blueGray" onPress={() => {
                setModalVisible(false);
              }}>
                Cancel
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal.Content>
      </Modal>


      <View
        style={{
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          // paddingHorizontal: ScreenSize().isWide ? '15%' : '100%',
          width: ScreenSize().isWide ? '85.5%' : '100%',
          alignSelf: 'center'
        }}
      // style={{ marginHorizontal: screenSize.isWide ? '9.2%' : 5 }}
      // style={{ marginHorizontal: screenSize.isWide ? screenSize.width / 11 : 5 }}
      >


        <ScrollView
          showsVerticalScrollIndicator={false}
          // style={{ overflow: 'visible' }}
          style={{
            height: '100%',
            width: '100%',
            paddingHorizontal: "2.3%",
            paddingBottom: 35
            // overflow: 'visible'
          }}
        >


          {/* Search Function ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
          <TextInput
            placeholder="Search"
            clearButtonMode="always"
            style={styles.textInput}
            autoCapitalize="none"
            value={searchQuery}
            onFocus={() => {
              setsearchAds(fullMeals)
              setChangingAds(fullMeals)
            }}
            onChangeText={(query) => {
              handleSearch(query)
            }
            }
          // autoComplete="name"
          // clearTextOnFocus
          // contextMenuHidden
          // enablesReturnKeyAutomatically
          // renderToHardwareTextureAndroid
          // returnKeyType="google"
          // autoCorrect
          />
          {searchQuery ? (
            <HorizontalScrollView
              data={searchAds}
              navigation={props.navigation}
              productsLength={searchAds.length}
            // text='All'
            // onPressAll={() => { props.navigation.navigate('ProductsList', { productType: 'all' }) }}
            >
            </HorizontalScrollView>
          ) : null}
          {/* Search Function ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}



          {/* Poster Image ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
          <Carousel
            draggable={true}
            infinite={false}
            pagingEnabled
            snapEnabled
            arrows={true}
            autoPlay={true}
            data={carouselImages}
            scrollAnimationDuration={2000}
            mode={screenSize.isWide ? undefined : 'parallax'}
            style={{
              alignSelf: 'center',
              marginTop: screenSize.isWide ? 10 : - screenSize.height / 60,
              overflow: 'hidden',
              // backgroundColor: 'red'
            }}
            height={screenSize.isWide ? screenSize.height / 3 : screenSize.height / 4}
            width={screenSize.isWide ? screenSize.width * 0.810 : screenSize.width * 1.222}
            // width={screenSize.isWide ? screenSize.width + (screenSize.width / 80) : screenSize.width * 1.222}
            // - screenSize.width / 7
            // onSnapToItem={(index) => console.log('current index:', index)}
            // maxScrollDistancePerSwipe={100}
            // loop
            renderItem={({ item, index }) => (
              <View
                style={{
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Image source={item} key={index} style={{
                  width: screenSize.isWide ? '100%' : '100%',
                  height: screenSize.isWide ? '100%' : '100%',
                  resizeMode: 'stretch',
                  borderRadius: 30,
                  borderWidth: 2,
                  borderColor: '#b0b0b0'
                }}>
                </Image>
              </View>
            )}
          ></Carousel>
          {/* Poster Image ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
          {/* Services ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
          <View style={{
            marginTop: screenSize.isWide ? 15 : - screenSize.height / 60,
            // marginBottom: 10
          }}>
            <View style={{ justifyContent: "center", alignItems: "center", }}>
              <Text
                style={{ fontSize: screenSize.isWide ? screenSize.width / 35 : screenSize.width / 16, fontWeight: "bold" }}
              >
                Learn More About Our Services
              </Text>
            </View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: 3
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setModalVisible(true); setModalText('Safe Product'); setModalImage(0); setModalDescription(
                    "If you are buying a laptop and worried about testing the device to make sure every thing is working properly...\nDon't worry, we got you !\nAll you have to do is giving us a call and within minutes you will have a specialist wherever you are to perform all the tests needed and give you a full report demonstrating the functionality of every component of the device, and also give you an advise weather to buy it or not and the average price for it in the market !")
                }}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginHorizontal: screenSize.width / 50,
                }}
              >
                <Image
                  style={{
                    height: (screenSize.height / 10.3) + (screenSize.width / 28.325),
                    width: (screenSize.height / 10.3) + (screenSize.width / 28.325),
                    maxWidth: 160,
                    maxHeight: 160,
                    borderRadius: 100,
                  }}
                  source={require('../assets/certified.jpg')}
                />
                <Text style={{ fontSize: 17, fontWeight: "400" }}>
                  Safe Product
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setModalVisible(true); setModalText('Delivery'); setModalImage(1); setModalDescription(
                    "Don't let the location be a problem to you any more, because we're here for you !\nIf you like a laptop so much but the seller is far away from you or you don't have the time to meet him, just call us and consider it done !\nYes, as you read, it's as easy as that !"
                  )
                }}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginHorizontal: screenSize.width / 20,
                }}
              >
                <Image
                  style={{
                    height: (screenSize.height / 10.3) + (screenSize.width / 28.325),
                    width: (screenSize.height / 10.3) + (screenSize.width / 28.325),
                    maxWidth: 160,
                    maxHeight: 160,
                    borderRadius: 100,
                  }}
                  source={require("../assets/delivery.jpg")}
                />
                <Text style={{ fontSize: 17, fontWeight: "400" }}>
                  Delivery
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={() => {
                  setModalVisible(true); setModalText('Anti Scam'); setModalImage(2); setModalDescription(
                    "You have an agreement with the seller that he's going to ship the laptop for you but you're both thinking a lot about weather the shipment first or the payment first, we can be your middle man to make sure as a seller that your product is safe in case the buyer didn't pay and to make sure as a buyer that your money is safe in case the seller didn't send the product. ")
                }}
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginHorizontal: screenSize.width / 50,
                }}
              >
                <Image
                  style={{
                    height: (screenSize.height / 10.3) + (screenSize.width / 28.325),
                    width: (screenSize.height / 10.3) + (screenSize.width / 28.325),
                    maxWidth: 160,
                    maxHeight: 160,
                    borderRadius: 100,
                  }}
                  source={require("../assets/scam.jpg")}
                />
                <Text style={{ fontSize: 17, fontWeight: "400" }}>
                  Anti Scam
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          {/* Services ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}

          {/* <View style={{ overflow: 'visible' }}> */}
          {/* <View style={{ width: '110%', alignSelf: 'center' }}> */}
          <HorizontalScrollView
            data={meals}
            text='All'
            productsLength={meals.length}
            onPressAll={() => { props.navigation.navigate('ProductsList', { productType: 'all' }) }}
            navigation={props.navigation}
          >
          </HorizontalScrollView>
          {/* ////////////////////////////////////////////////////////GAMING LAPTOPS///////////////////////////////////////////////////////////////// */}
          <HorizontalScrollView
            data={gamingLaptops}
            text='Gaming Laptops'
            productsLength={gamingLaptops.length}
            onPressAll={() => { props.navigation.navigate('ProductsList', { productType: 'gamingLaptop' }) }}
            navigation={props.navigation}
          >
          </HorizontalScrollView>
          {/* ////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          <HorizontalScrollView
            data={businessLaptops}
            text='Business Laptops'
            productsLength={businessLaptops.length}
            onPressAll={() => { props.navigation.navigate('ProductsList', { productType: 'businessLaptop' }) }}
            navigation={props.navigation}
          >
          </HorizontalScrollView>
          {/* /////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
          <HorizontalScrollView
            data={workstations}
            text='Workstations'
            productsLength={workstations.length}
            onPressAll={() => { props.navigation.navigate('ProductsList', { productType: 'workstation' }) }}
            navigation={props.navigation}
          >
          </HorizontalScrollView>
          {/* </View> */}

        </ScrollView>
      </View>
      {/* </LinearGradient> */}
    </View>
  );
};

export default HomeScreen;

function useStyles() {
  return StyleSheet.create({
    textInput: {
      fontSize: ScreenSize().isWide
        ? ScreenSize().width / 50
        : ScreenSize().width / 19,
      paddingLeft: 10,
      borderWidth: 2,
      borderRadius: 8,
      borderColor: "#ccc",
      paddingVertical: 10,
      marginTop: 6,
      paddingHorizontal: 20,
      shadowOffset: 50,
      shadowOpacity: 30,
      elevation: 30,
    },
    textShortDes: {
      fontSize: 12,
      fontWeight: "600",
    },
    textLongDes: {
      fontSize: 10,
      fontWeight: "400",
      color: "#000",
      opacity: 0.9,
      width: 120,
    },
    sheet: {
      // // backgroundColor: "rgba(0, 0, 0, 0.3)",
      // backgroundColor: "white",
      // padding: 16,
      // height: '30%',
      // // height: ScreenSize().height / 1.5,
      // width: "70%",
      // position: "absolute",
      // // bottom: -ScreenSize().height / 6,
      // borderRadius: 20,
      // zIndex: 1,
      // alignSelf: 'center',
      // bottom: ScreenSize().height * 0.35
      // // marginHorizontal: 1000,
      // // bottom: -20 * 7,
      // // borderTopRightRadius: 20,
      // // borderTopLeftRadius: 20,

      backgroundColor: "white",
      padding: 16,
      height: ScreenSize().height / 4,
      width: "100%",
      position: "absolute",
      bottom: -ScreenSize().height / 8,
      borderRadius: 20,
      zIndex: 1,
    },
    backdrop: {
      ...StyleSheet.absoluteFillObject,
      backgroundColor: "rgba(0, 0, 0, 0.3)",
      zIndex: 1,
    },
  });
}
