import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  StyleSheet,
  LogBox,
  // Button,
  // Alert,
} from "react-native";
import * as Location from "expo-location";
import * as Permissions from "expo-permissions";
// import firebase from "../firebase/firebaseConfig";

// import Colors from '../costants/Colors';
import { COLOURS } from "../components/database/Database";

// import MapPreview from "./MapPreview";
import { TouchableOpacity } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";

LogBox.ignoreLogs(
  ["expo-permissions is now deprecated"],
  [
    "ImagePicker.requestCameraRollPermissionsAsync() is deprecated in favour of ImagePicker.requestMediaLibraryPermissionsAsync()",
  ]
);

const LocationPicker = (props, { navigation, route }) => {
  const [isFetching, setIsFetching] = useState(false);
  const [pickedLocation, setPickedLocation] = useState(null);
  // const [address, setAddress] = useState("");

  // useEffect(() => {
  //   // console.log(route?.params)
  //   // console.log(props.lastAdLocation)
  //   // console.log(props.route?.params)
  //   // console.log(route?.params?.lastAdLocation)
  //   // console.log(props.route?.params?.lastAdLocation)
  //   if (props.lastAdLocation !== undefined) {
  //     // console.log('props.route?.params?.lastAdLocation !== undefined')
  //     setPickedLocation(props.lastAdLocation?.coordinates)
  //     setAddress(props.lastAdLocation?.address)
  //     props.onAddressPicked(props.lastAdLocation?.address);
  //     props.onLocationPicked({
  //       lat: props.lastAdLocation?.coordinates.lat,
  //       lng: props.lastAdLocation?.coordinates.lng,
  //     });
  //   } else {
  //     // console.log('props.route?.params?.lastAdLocation == undefined')
  //   }
  // }, [])

  // const mapPickedLocation = props.route.params.pickedLocation
  // const mapPickedLocation = props.navigation.getParam('pickedLocation');

  const { onLocationPicked } = props;

  // useEffect(() => {
  //     if (mapPickedLocation) {
  //         setPickedLocation(mapPickedLocation);
  //         onLocationPicked(mapPickedLocation);
  //     }
  // }, [mapPickedLocation, onLocationPicked])

  const verifyPermissions = async () => {
    const result = await Permissions.askAsync(Permissions.LOCATION_FOREGROUND);
    // const result = await Permissions.askAsync(Permissions.LOCATION);
    if (result.status !== "granted") {
      alert(
        // Alert.alert(
        "Insufficient permissions!",
        "You need to grant location permissions to use this app",
        [{ text: "Okay" }]
      );
      return false;
    }
    return true;
  };

  const getLocationHandler = async () => {
    const hasPermission = await verifyPermissions();
    if (!hasPermission) {
      return;
    }

    try {
      setIsFetching(true);
      // setTimeout( async() => {
      // }, 3000)
      const location = await Location.getCurrentPositionAsync({
        accuracy: Location.Accuracy.BestForNavigation,
        // timeout: 0,
      })
      // const location = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Highest })

      // const location = await Location.getCurrentPositionAsync({
      //   accuracy: true,
      //   mayShowUserSettingsDialog: true,
      //   // timeout: 50000,
      //   // timeInterval
      //   // distanceInterval
      //   // maxAge: 100,
      // });
      //   console.log(location.coords.latitude, location.coords.longitude);
      setPickedLocation({
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      });

      props.onLocationPicked({
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      });

      fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        location.coords.latitude +
        "," +
        location.coords.longitude +
        "&key=AIzaSyCEOCYVcrRh3fdxe199TzLIuRDE5lgBmuY"
      )
        // fetch('https://maps.googleapis.com/maps/api/geocode/json?address=' + location.coords.latitude + ',' + location.coords.longitude + '&key=AIzaSyCZ_l_v1gVoj9mHGOrL_ugNQLiCioZK4hs')
        .then((response) => response.json())
        .then((responseJson) => {
          // setAddress(
          //   responseJson.results[responseJson.results.length - 3]
          //     .formatted_address
          // );

          // props.onAddressPicked(
          //   responseJson.results[responseJson.results.length - 3]
          //     .formatted_address
          // );
          props.onAddressPicked(responseJson);

          // console.log(responseJson.results[responseJson.results.length - 3]);
          // console.log(responseJson.results);

          // const city = data.results[0].address_components.find(
          //   (component) => component.types.includes("locality")
          // ).long_name;
          // props.onAddressPicked(responseJson.results[3].formatted_address);
          // console.log(responseJson.results.length);
          // console.log(
          //   responseJson.results[responseJson.results.length - 3]
          //     .formatted_address
          // );
          // setInterval(() => {
          // }, 5000);
          // }, 10000);
        });
    } catch (err) {
      alert(
        // Alert.alert(
        "Could not fetch location!",
        "Please try again later or pick a location on the map.",
        [{ text: "Okay" }]
      );
    }
    setIsFetching(false);
  };

  // const pickOnMapHandler = () => {
  //   window.open("https://maps.google.com?q=" + pickedLocation.lat + "," + pickedLocation.lng);
  // };
  // const pickOnMapHandler = () => {
  //   // props.navigation.navigate('AddScreen' , {initialLocation: {lat:28.08644113778082,lng:30.81465315073729}});
  //   // props.navigation.navigate('MapScreenAdd', { initialLocation: { lat: 28.08644113778082, lng: 30.81465315073729 } });
  //   props.navigation.navigate("MapScreen", {
  //     initialLocation: { lat: 28.08644113778082, lng: 30.81465315073729 },
  //   });
  // };
  // console.log(pickedLocation)
  return (
    <View style={styles.locationPicker}>
      {/* {pickedLocation ? (
        <MapPreview
          style={styles.mapPreview}
          location={pickedLocation}
          onPress={pickOnMapHandler}
        ></MapPreview>
      ) : null} */}

      {/* <Text style={{ fontSize: 14, fontWeight: "400" }}>{address}</Text> */}

      {/* <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          //   backgroundColor: "#7ac77a",
        }}
      >
        <Text
          style={{
            fontSize: 14,
            fontWeight: "400",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {address}
        </Text>
      </View> */}

      {pickedLocation ? (
        <TouchableOpacity
          style={{ alignItems: "center", justifyContent: "center" }}
          onPress={() => {
            setPickedLocation(null);
            // setAddress(null);
            props.onLocationPicked(null);
            props.onAddressPicked(null)
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginHorizontal: 20,
              margin: 8,
            }}
          >
            <MaterialCommunityIcons
              name="close-circle"
              size={24}
              color="#52575D"
            />
          </View>
        </TouchableOpacity>
      ) : null}

      {
        isFetching ? (
          <ActivityIndicator size="large" color={COLOURS.backgroundMedium} />
        ) : null
        // (
        //     <Text>No location chosen yet!</Text>
        // )
      }

      <View style={styles.actions}>
        <TouchableOpacity style={{ backgroundColor: '#eaeaea', paddingVertical: 15, borderRadius: 7, width: '100%', alignItems: "center" }} onPress={getLocationHandler}>
          <Text style={{ color: '#484848', fontSize: 16, fontWeight: '500' }}>Get Location</Text>
        </TouchableOpacity>
        {/* <Button
          title="Get Location"
          color='#f6f6f6'
          onPress={getLocationHandler}
        /> */}
        {/* <Button title="Pick On Map" color={COLOURS.backgroundMedium} onPress={pickOnMapHandler} /> */}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  locationPicker: {
    // marginBottom: 5,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  mapPreview: {
    marginBottom: 10,
    width: "100%",
    height: 150,
    borderColor: "#ccc",
    borderWidth: 1,
  },
  actions: {
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
  },
  imagePicker: {
    alignItems: "center",
    marginBottom: 15,
  },
  imagePreview: {
    width: "100%",
    height: 200,
    marginBottom: 10,
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#ccc",
    borderWidth: 1,
  },
  image: {
    width: "100%",
    height: "100%",
  },
});

export default LocationPicker;
